import Bulk_payment_summary from '../../../../../../../ui/ReuseableComponents/BulkPaymentSummary/BulkPaymentSummary'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import Waiting from '../../../../../../../ui/ReuseableComponents/Notifications/Waiting/Waiting'
import ProgressComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress'


const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  min-width: 500px;
  max-width: 600px;
`

const PendingPopup = () => (
    <OverlayWrapper>
        <PopupContainer>
            <ProgressComponent
                text="Your transaction is being processed. Please do not navigate away from this screen"
            />
        </PopupContainer>
    </OverlayWrapper>
)

const WaitingPopup = () => (
    <OverlayWrapper>
        <PopupContainer>
            <Waiting
                heading="Transaction in progress"
                text1="The payment is still being processed. We request for your patience as we work to complete the process."
                text2="In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions."
                onClick={() => { window.location.href = '/balance' }}
                buttonText="Close"
            />
        </PopupContainer>
    </OverlayWrapper>
)

function Step7({
    loaderStatus,
    setTransaction_detailReload,
    formik,
    setFormik,
    setUseLayout,
    stepupdatefun,
    selectedRetryIds,
    setSelectedRetryIds,
    retryPaymentOtp,
    paymentApiResdata }: any) {
    return (
        <Box>
            <Bulk_payment_summary
                setTransaction_detailReload={setTransaction_detailReload}
                formik={formik}
                setFormik={setFormik}
                setUseLayout={setUseLayout}
                stepupdatefun={stepupdatefun}
                selectedRetryIds={selectedRetryIds}
                setSelectedRetryIds={setSelectedRetryIds}
                retryPaymentOtp={retryPaymentOtp}
                paymentApiResdata={paymentApiResdata}
            />
            {loaderStatus === 'pending' && <PendingPopup />}
            {loaderStatus === 'waiting' && <WaitingPopup />}
        </Box>
    )
}

export default Step7