import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { selectAllPayablesListData } from '../../../../_reducers/payables.reducer';
import Modal from 'react-bootstrap/Modal';
import { Box } from '@mui/material';
import styles from "./bulkpaymentsummary.module.scss"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
} declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

interface Data {
    transaction_date: Date;
    reference: string | number;
    type: string | number;
    method: number;
    category: string;
    payee: string;
    transaction_amount: string | number;
    transaction_status: string | number;
    transaction: string | number;
    status: string | number;
}

function createData(
    transaction_date: Date,
    reference: string | number,
    type: string | number,
    method: number,
    category: string,
    payee: string,
    transaction_amount: string | number,
    transaction_status: string | number,
    transaction: string | number,
    status: string | number
): Data {
    return {
        transaction_date,
        reference,
        type,
        method,
        category,
        payee,
        transaction_amount,
        transaction_status,
        transaction,
        status
    };
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Payment Reference',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'method',
        numeric: false,
        disablePadding: false,
        label: 'Payment Method',
    },

    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'payee',
        numeric: false,
        disablePadding: false,
        label: 'Payee ',
    },
    {
        id: 'transaction',
        numeric: false,
        disablePadding: false,
        label: 'Transaction',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    status: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, status } =
        props;
    var newHeadcells = headCells
    return (
        <thead>
            <tr className={styles.baahhajhajaha}>
                {newHeadcells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={`${styles.table_head_cell} th`}
                        style={{ backgroundColor: "#FBFBFB" }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </tr>
        </thead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

const dailogHead = [
    {

        label: 'Payment reference',
    },
    {

        label: 'Type',
    },
    {

        label: 'Payment method',
    },
    {

        label: 'Payee',
    },

    {

        label: 'Transaction',
    },
    {

        label: 'Failure reason',
    },

];

const Bulk_payment_summary = (props: any) => {
    const rows: any[] = props.data ? props.data : [];
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('transaction_date');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [panding, setPanding_data] = React.useState(false)
    const [stast_api, setStast_api] = React.useState(false)
    const [topbox_data, setTop_box_data] = React.useState<any>()
    const [transRender, setTransRender] = React.useState(false);
    const [requestRender, setrequestRender] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [responseRender, setresponseRender] = React.useState(false);
    const [otpFailed, setotpFailed] = React.useState(false);
    const [data_tanjecasan, setData_tanjeksan] = React.useState<any>()
    const [datainline, seDatainline] = React.useState<any>(false);
    const [showModal, setShow] = React.useState(true);
    const [pandinglog, setPandingdata] = React.useState(false)
    // const [Displaydata, setDisplaydata] = React.useState(true)
    const [pendingclos, setPendingclos] = React.useState(false)
    const [showModals, setShowModal] = useState(false);
    const [insetapireduser, setInsetapireduser] = React.useState(true)
    const dispatch = useDispatch()
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const paybill_data = useSelector((state) => selectAllPayablesListData(state))
    var dataNew = paybill_data
    if (dataNew != paybill_data) {
        dataNew = paybill_data
    }

    React.useEffect(() => {
        if (responseRender && dataList?.GET_BULK_PAYMENTS_STATUS_REQUEST == false) {
            setLoading(false);
            setrequestRender(true);
            setresponseRender(false);
        }
    }, [dataList, responseRender]);
    React.useEffect(() => {
        if (
            otpFailed &&
            dataList?.GET_BULK_PAYMENTS_STATUS_FAILURE !== undefined
        ) {
            setTransRender(false);
            setotpFailed(false);
            onClose()
        }
    }, [dataList, otpFailed]);
    // React.useEffect(() => {
    //     if (props.Displaydata == true) {
    //         props.setDisplaydata(true)
    //         setTimeout(() => {
    //             setStast_api(true)
    //         }, 4000)

    //     }
    // }, [props.Displaydata]);


    React.useEffect(() => {
        if (props.Displaydata && paybill_data.INITIATE_BULK_PAYMENT_SUCCESS !== undefined) {
            props.setDisplaydata(false)
            setLoading(true)
            setPanding_data(true)
            setShow(true)
            seDatainline(true)
            dispatch(paymentJourneyActions.getbulk_payments_status_active({ "data": props.id }) as any)
        }
    }, [props.Displaydata, paybill_data.INITIATE_BULK_PAYMENT_SUCCESS]);


    const processTransactionItem = (item: any) => ({
        commonId: item?.id,
        id: item?.transaction_id,
        created_at: item?.created_at,
        type: item?.type,  
        method: item?.payment_channel,
        category: item?.categories ? JSON.parse(item.categories)?.map((cat: any) => cat?.name)?.join(', ') : '',
        payee: item?.payee,
        currency: item?.currency,
        transaction: `${item?.currency ?? ''} ${Number(item?.amount ?? 0).toLocaleString('en', { minimumFractionDigits: 2 })}`,
        status: item?.status,
        message: item?.message
    });

    React.useEffect(() => {
        if (props.paymentApiResdata) {
            const isMultipleBills = Array.isArray(props?.paymentApiResdata?.data); 
            

            const processedData = isMultipleBills 
            ? (props.paymentApiResdata?.data || []).map((item: any) => processTransactionItem(item)) 
            : props.paymentApiResdata?.data 
                ? [processTransactionItem(props.paymentApiResdata?.data)]  
                : [];

            setData_tanjeksan(processedData);    


            const payoutDetails = isMultipleBills
                ? props.paymentApiResdata?.payout_details
                : calculateSinglePayoutDetails(props.paymentApiResdata?.data);

            setTop_box_data({
                gross_total_succeeded: payoutDetails?.gross_total_succeeded ?? 0,
                transactions_succeeded: payoutDetails?.transactions_succeeded ?? 0,
                gross_total_failed: payoutDetails?.gross_total_failed ?? 0,
                transactions_failed: payoutDetails?.transactions_failed ?? 0,
            });

            seDatainline(true);

            const batchStatus = isMultipleBills 
                ? props.paymentApiResdata?.batch_status
                : props.paymentApiResdata?.data?.status === 'success';

            if (batchStatus) {
                setPanding_data(false);
                setTimeout(() => {
                    onClose();
                }, 200);
            }
        }
    }, [props.paymentApiResdata]);


    React.useEffect(() => {   
        console.log(data_tanjecasan, "wajidprocesseddata (AFTER STATE UPDATE)");
    }, [data_tanjecasan]); 

    const calculateSinglePayoutDetails = (data: any) => {
        if (!data) return null;

        const amount = Number(data?.amount ?? 0) * 100;
        const isSuccess = data?.status === 'success';
        const isFailed = data?.status === 'failed';

        return {
            gross_total_succeeded: isSuccess ? amount : 0,
            transactions_succeeded: isSuccess ? 1 : 0,
            gross_total_failed: isFailed ? amount : 0,
            transactions_failed: isFailed ? 1 : 0,
        };
    };
    React.useEffect(() => {
        if (props.paymentApiResdata?.data) {
            const isMultipleBills = Array.isArray(props.paymentApiResdata?.data);
        
            const failedTxns = isMultipleBills
                ? (props.paymentApiResdata?.data || [])
                    .filter((item: any) => item?.status === 'failed')
                    .map(mapFailedTransaction)
                : props.paymentApiResdata?.data?.status === 'failed'
                    ? [mapFailedTransaction(props.paymentApiResdata.data)]
                    : [];
        
            console.log("Failed Transactions:", failedTxns);
        
            setFailedTransactions(failedTxns);
        }
    }, [props.paymentApiResdata]);

    const mapFailedTransaction = (item: any) => ({
        commonId: item?.id,
        id: item?.transaction_id,
        method: item?.payment_channel,
        description: item?.description,
        amount: `${item?.currency ?? ''} ${Number(item?.amount ?? 0).toLocaleString('en', { minimumFractionDigits: 2 })}`,
        currency: item?.currency,
        error: item?.message
    });

    React.useEffect(() => {
        if (insetapireduser && paybill_data.INITIATE_BULK_PAYMENT_FAILURE !== undefined) {
            setInsetapireduser(false)
        }
    }, [paybill_data, insetapireduser]);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const onClose = () => {
        setShow(false);
    }

    const handleOpenModal = () => {
        console.log("model opn", showModals)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const [failedTransactions, setFailedTransactions] = useState<any[]>([]);

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            props.setSelectedRetryIds(failedTransactions.map(bill => bill.commonId)); 
        } else {
            props.setSelectedRetryIds([]);
        }
    };

    const handleSelectRow = (commonId: string) => {
        props.setSelectedRetryIds((prevSelected: any) =>
            prevSelected.includes(commonId)
                ? prevSelected.filter((item: any) => item !== commonId)
                : [...prevSelected, commonId]
        );
    };

    const renderSummaryCards = () => (
        <Box className={styles.main_card_contean_data}>
            <Box style={{ background: "#FBFBFB"}} className="balance__card"> 
                <Box sx={{ lineHeight: "21px", fontSize: "14px", fontWeight: "500", color: "#04715C !important" }} >
                    Successful Payments
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "15px" }} >
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>
                            Total Payments
                        </span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }} >
                            {`${props?.paymentApiResdata?.data?.[0]?.currency ?? ''} ${topbox_data?.gross_total_succeeded ?
                                Number((topbox_data?.gross_total_succeeded / 100).toFixed(2))
                                    .toLocaleString('en', { minimumFractionDigits: 2 })
                                : "0.00"}`}
                        </span>
                    </Box>
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>
                            No. of Payments
                        </span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }}>
                            {topbox_data?.transactions_succeeded || 0}
                        </span>
                    </Box>
                </Box>
            </Box>
            <Box style={{ background: "#FBFBFB", padding: "14px 3px 14px 10px"}} className="balance__card">
                <Box sx={{ lineHeight: "21px", fontSize: "14px", fontWeight: "500", color: "#EC7F00" }} >Pending payments</Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "15px" }} >
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>Total Payments</span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }} >
                            {`${props?.paymentApiResdata?.data?.[0]?.currency ?? ''} ${topbox_data?.gross_total_pending ?
                                Number((topbox_data.gross_total_pending / 100).toFixed(2))
                                    .toLocaleString('en', { minimumFractionDigits: 2 })
                                : "0.00"}`}
                        </span>
                    </Box>
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>No. of Payments</span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }}>
                            {topbox_data?.transactions_pending || 0}
                        </span>
                    </Box>

                </Box>
            </Box>
            <Box onClick={handleOpenModal} style={{ cursor: "pointer", background: "#FBFBFB"}} className="balance__card">
                <Box sx={{ lineHeight: "21px", fontSize: "14px", fontWeight: "500", color: "#EC0000" }} >Failed payments</Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "15px" }} >
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>Total Payments</span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }} >
                            {`${props?.paymentApiResdata?.data?.[0]?.currency ?? ''} ${topbox_data?.gross_total_failed ?
                                Number((topbox_data.gross_total_failed / 100).toFixed(2))
                                    .toLocaleString('en', { minimumFractionDigits: 2 })
                                : "0.00"}`}
                        </span>
                    </Box>
                    <Box className="balance__card__row">
                        <span style={{ width: "50%", color: "#6D6B6B", fontSize: "12px", fontWeight: "500" }}>No. of Payments</span>
                        <span style={{ color: "#1D1D1D", width: "50%", fontSize: "12px", fontWeight: "500" }}>
                            {topbox_data?.transactions_failed || 0}
                        </span>
                    </Box>

                </Box>
            </Box>

        </Box>
    );

    const renderTransactionTable = () => (
        <TableContainer>
            <Table className={styles.tebal_contean_data}>
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}     
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                    status={false}
                />
                <tbody className={styles.ajSDjkjadjdkdjd}>
                    {data_tanjecasan?.map((element: any, index: number) => {
                        const tagInfo = getTagInfo(element.type);
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography className={`${styles.table_body_cell} tebaldateformette`}>
                                        {moment(element.created_at).format("DD-MMM-YYYY")}
                                    </Typography>
                                </TableCell>
                                <TableCell className={`${styles.table_body_cell}`}>{element.id}</TableCell>
                                <TableCell className="type tag">
                                    <span style={{ fontSize: "11px" , padding:"4px 11px"}} className={`tag_color ${tagInfo.tagclass} ${styles.table_body_cell}`}>
                                        {tagInfo.tagname}
                                    </span>
                                </TableCell>
                                <TableCell><Typography className={`${styles.table_body_cell} tebaldateformette`}>
                                    {element.method}</Typography></TableCell>
                                <TableCell><Typography className={`${styles.table_body_cell} tebaldateformette`}>
                                    {element.category}</Typography></TableCell>

                                <TableCell><Typography className={`${styles.table_body_cell} tebaldateformette`}>
                                    {element.payee}</Typography></TableCell>
                                <TableCell className='balance-category'>
                                    <Typography className={`${styles.table_body_cell} tebaldateformette`}>
                                        {element.transaction}</Typography></TableCell>



                                <TableCell>
                                    <Typography className={`${getStatusClass(element.status)} ${styles.table_body_cell}`}>
                                        {element.status}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </tbody>
            </Table>
        </TableContainer>
    );

    const getTagInfo = (type: string) => {
        switch (type) {
            case 'Pay in':
                return { tagclass: 'tag_payin-tag', tagname: 'Pay In' };
            case 'Pay out':
                return { tagclass: 'tag_payout-tag', tagname: 'Pay Out' };
            case 'Fees':
                return { tagclass: 'tag_fees-tag', tagname: 'Fees' };
            default:
                return { tagclass: '', tagname: type };
        }
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'failed':
                return styles.Failed_calss;
            case 'success':
                return styles.Success_calss;
            default:
                return styles.Pending_calss;
        }
    };

    return (
        <>
            <Dialog
                sx={{ "& .MuiPaper-root.MuiDialog-paper": { width: "100%", maxWidth: "80%" } }}
                open={showModals}
                onClose={handleCloseModal}
            >
                {
                    props.paymentApiResdata?.payout_details?.transactions_failed > 0 ? <>
                        <DialogTitle sx={{ color: "#EC0000", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "16px" }} >
                            <ErrorOutlineIcon sx={{ marginRight: "10px" }} />
                            You have {props.paymentApiResdata?.payout_details?.transactions_failed || 0} failed transactions
                        </DialogTitle>
                        <DialogContent>
                            <Box style={{ borderRadius: "5px", background: "#FFF", width: "100%", alignItems: 'center', display: "flex", justifyContent: "center" }}>
                                <Box style={{ width: "100%" }}>
                                    <TableContainer>
                                        <Table
                                            className='contenar_tebal_liner'
                                            aria-labelledby="tableTitle"
                                            style={{ width: "100%" }}

                                        >
                                            <TableHead sx={{ background: "#FBFBFB !important", minWidth: "100%" }}>
                                                <TableRow style={{ minWidth: "100% !important" }} >
                                                    <TableCell sx={{ background: "#FBFBFB !important" }} style={{ borderBottom: "0px solid" }} padding="checkbox">

                                                        <Checkbox
                                                            color="primary"
                                                            icon={<img src="/Checkbox.svg" />}
                                                            checkedIcon={<img src="/Checkbox_two.svg" />}
                                                            checked={props.selectedRetryIds.length === failedTransactions.length}
                                                            indeterminate={props.selectedRetryIds.length > 0 && props.selectedRetryIds.length < failedTransactions.length}
                                                            onChange={handleSelectAll}
                                                            inputProps={{ 'aria-label': 'select all drafts' }}
                                                        />

                                                    </TableCell>
                                                    {dailogHead.map((headCell) => (
                                                        <TableCell className={styles.table_head_cell} sx={{ background: "#FBFBFB !important", minWidth: "100px"}} style={headCell.label == "Description" ? { textAlign: "center" } : { borderBottom: "0px solid" }} >
                                                            {headCell.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {failedTransactions.map((bill: any) => (
                                                    <TableRow key={bill.id} role="checkbox" sx={{ cursor: 'pointer' }}>
                                                        <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                            <Checkbox
                                                                icon={<img src="/Checkbox.svg" />}
                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                color="primary"
                                                                checked={props.selectedRetryIds.includes(bill.commonId)}
                                                                onChange={() => handleSelectRow(bill.commonId)}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ cursor: "pointer", borderBottom: "0px solid" }} className={styles.table_body_cell}>{bill.id}</TableCell>
                                                        <TableCell style={{ borderBottom: "0px solid" }}>
                                                            <Button style={{ background: "#1F1549", color: "#FFFFFF", border: "0px solid"}} className={styles.table_body_cell} >
                                                                Pay out
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell className={styles.table_body_cell} style={{ borderBottom: "0px solid" }}>{bill.method}</TableCell>
                                                        <TableCell className={styles.table_body_cell} style={{ borderBottom: "0px solid" }}>{bill.description}</TableCell>
                                                        <TableCell className={styles.table_body_cell} style={{ borderBottom: "0px solid" }}>{bill.amount}</TableCell>
                                                        <TableCell className={styles.table_body_cell} style={{ borderBottom: "0px solid", color: "#EC0000" }}>{bill.error}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ gap: "20px", padding: "20px" }}> 

                            <Button style={{ padding: "11px 40px" }} className={`balance__green-btn ${styles.btn_summary}`} 
                                onClick={handleCloseModal}
                            >
                                Cancel</Button>
                            <Button className={`auth-trans-container__btn-row__green ${styles.btn_summary}`} 
                                disabled={props.selectedRetryIds.length === 0}
                                onClick={() => { props.retryPaymentOtp() }}
                            >
                                Retry payment</Button>
                        </DialogActions> 
                    </> : <>
                        <DialogTitle sx={{ color: "#EC0000", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "16px" }} >
                            <ErrorOutlineIcon sx={{ marginRight: "10px" }} />
                            You have {props.paymentApiResdata?.payout_details?.transactions_failed || 0} failed transactions
                        </DialogTitle>
                        <DialogActions sx={{ gap: "20px", padding: "20px" }}>
   
                            <Button style={{ padding: "11px 40px" }} className={`balance__green-btn ${styles.btn_summary}`} 
                                onClick={handleCloseModal}
                            >
                                Cancel</Button>

                        </DialogActions>
                    </>
                }
            </Dialog >
            <Box className="balance" style={{ maxWidth: "100%" }}>
                {renderSummaryCards()}
                <Box sx={{ margin: "18px 0" }}>
                    <ThemeProvider theme={theme}>
                        <Box style={{ padding: "0px !important", background: "#FBFBFB", paddingBottom: "10px" , minWidth:"100%" }} className='contenar_the_data'>
                            {renderTransactionTable()}
                        </Box>
                    </ThemeProvider>   
                    <Box className={styles.main_contenar_listts_footer} style={{marginTop:"35px"}}>
                        <Box sx={{ gap: "20px" }} className={styles.main_contenar_listts_footer}>
                            <Button className={`balance__black-btn ${styles.btn_summary}`} 
                                onClick={() => {
                                    props.setFormik({
                                        totalAmount: 0,
                                        selectedBills: [],
                                        counterpart: [],
                                        currency: "KES",
                                        country_id: "1",
                                        apiUpdatedBills: [],
                                        balance: 0,
                                        methoadSelect: [],
                                        grossAmount: 0,
                                        transactionAmount: 0,
                                        totalFees: 0,
                                        "payment_details": [],
                                      })
                                    window.location.href = "/balance" }}
                            >
                                Go Back</Button>
                            <Button className={`balance__green-btn ${styles.btn_summary}`} 
                                onClick={handleOpenModal}
                            >
                                View failed payments</Button>
                        </Box>
                        <Button className={`auth-trans-container__btn-row__green ${styles.btn_summary}`} 
                            onClick={() => {
                                props.setUseLayout("defaultlayout");
                                props.stepupdatefun(0);
                                props.setTransaction_detailReload(false);
                                props.setFormik({
                                    totalAmount: 0,
                                    selectedBills: [],
                                    counterpart: [],
                                    currency: "KES",
                                    country_id: "1",
                                    apiUpdatedBills: [],
                                    balance: 0,
                                    methoadSelect: [],
                                    grossAmount: 0,
                                    transactionAmount: 0,
                                    totalFees: 0,
                                    "payment_details": [],
                                  })
                            }}
                        >
                            Make Another Payment</Button>
                    </Box>
                </Box>
            </Box>


        </>
    )
}
export default Bulk_payment_summary;