import { Autocomplete, Box, TextField } from '@mui/material'
import Step1 from './Components/Step1/Step1'
import Step2 from './Components/Step2/Step2'
import Step4 from './Components/Step4/Step4'
import { payablesFlowactions, userActions } from '../../../../../../_actions'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Step3 from './Components/Step3/Step3'
import Step5 from './Components/Step5/Step5'
import Step6 from './Components/Step6/Step6'
import Step7 from './Components/Step7/Step7'
import { settingSelector } from '../../../../../../_reducers/setting.reducer'
import { useSelector } from 'react-redux';




const BillPayment = ({ setIsStepsNavHidden,setGoBackButton, setCurrencyedit, Currencyedit, setCountriyIdEDIT, CountriyIdEDIT, SelectCurrency, setSelectedUser, selectedUser, setIsAddPayeeComponent, isAddPayeeComponent, setIsGoBackHidden, selectedCurrency, selectedFlag, Step, stepupdatefun, setUseLayout, SelectCountry_id }: any) => {
  const [render, setRender] = useState(true)
  const [areBillsAvailable, setAreBillsAvailable] = useState("")
  const [payablesData, setPayablesData] = useState<any>([])
  const [dataSave, setDataSave] = useState([])
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [totalCount, setTotalCount] = useState(10);
  const [bankData, setBankData] = useState<any[]>([])
  const [categoriesData, setCategoriesData] = useState<any[]>([])
  const [teamData, setTeamData] = useState<any[]>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [UpdetPayeeId, setUpdetPayeeId] = useState('')
  const [loaderStatus, setLoaderStatus] = useState('')
  const [transaction_detailReload, setTransaction_detailReload] = useState(false)
  const [loader, setLoader] = useState(false)
  const [time, setTime] = useState(0);
  const [enableButton, setEnableButton] = useState(false);
  const [errorsMsg, setErrorMsg] = useState('')
  const [errorsmsgapi, setErrorMsgapi] = useState("")
  const [isEditPayeeApiCalled, setIsEditPayeeApiCalled] = useState(false);
  const [TransactionSuccess, setTransactionSuccess] = useState([])
  const [selectedBillIds, setSelectedBillIds] = useState<string[]>([]);
  const [PayloadBulk, setPayloadBulk] = useState([])
  const [selectedRetryIds, setSelectedRetryIds] = useState<string[]>([]);
  const [singleTransactionDetailsReload, setSingleTransactionDetailsReload] = useState(false);
  const [singlePaymentTransactionSuccessData, setSinglePaymentTransactionSuccessData] = useState({});
  const [notifyPayment, setNotifyPayment] = useState(false);
  const [isSchedulePayment, setIsSchedulePayment] = useState(false);

  const [formik, setFormik] = useState<any>({
    totalAmount: 0,
    selectedBills: [],
    counterpart: [],
    currency: "KES",
    country_id: "1",
    apiUpdatedBills: [],
    balance: 0,
    methoadSelect: [],
    grossAmount: 0,
    transactionAmount: 0,
    totalFees: 0,
    "payment_details": [],
  })
  const [loaderPayment, setLoaderPayment] = useState(false)
  const [getPayees, setGetPayees] = useState([])
  const [paymentApiResdata, setPaymentApiResdata] = useState([])
  const [searchPayee, setSearchPayee] = useState("")
  const [users, setUsers] = useState<any>();
  const userData = useSelector((state) => settingSelector(state));
  const getCounterparts = (data: any) => {
    console.log(data, "datadata");

    setGetPayees(data)
    setLoader(false)
  }
  useEffect(() => {
    if (userData?.GETUSERDETAIL_SUCCESS) {
      setUsers(userData.GETUSERDETAIL_SUCCESS);
      console.log(users, "wwusers")
    }
  }, [SelectCurrency]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doneTyping();
    }, 1000);
    return () => clearTimeout(timeoutId);
    // }
  }, [SelectCurrency, searchPayee])

  const doneTyping = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
  }
  const dispatch = useDispatch()
  console.log(formik, "payablesData formik");

  const handleClick = (
    event: React.MouseEvent<unknown>,
    index: number,
    Nametwo: string
  ) => {
    const selectedIndex = selected.indexOf(Nametwo);

    let newSelected: readonly string[] = [];
    let Selectedarray: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Nametwo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // const Selectedarray = payablesData.map((n: any) => n.id == Nametwo);
    // 
    console.log(Nametwo, payablesData, "elementelement");

    for (let index = 0; index < payablesData.data.length; index++) {
      const element = payablesData.data[index];
      console.log(Nametwo, element, Selectedarray.concat(element), Selectedarray, "elementelement");

      if (element.id == Nametwo) {
        Selectedarray = Selectedarray.concat(element)
      }

    }
    console.log(Selectedarray, "Selectedarray");

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = payablesData.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const getPayables = (data: any) => {
    setLoader(false)
    console.log(data, "paydata")
    setPayablesData(data)
    setTotalCount(data.total);
    if (data?.data?.length < 1) {
      setAreBillsAvailable(`No bills available according to selected Currency`)
    } else {
      setAreBillsAvailable("")
    }
  }
  useEffect(() => {
    if (!!selectedCurrency) {
      setFormik({ ...formik, currency: selectedCurrency })
    }
  }, [selectedCurrency])
  useEffect(() => {
    if (render || selectedCurrency) {
      setFormik({ ...formik, currency: selectedCurrency })
      setFormik({ ...formik, country_id: SelectCountry_id })
      setLoader(true);
      dispatch(payablesFlowactions.getApipayment(`/v4/get-payables/${selectedCurrency}?page=${pageneshn}&limit=${selectedLimit}`, getPayables) as any)
      setRender(false)
    }
  }, [render, selectedCurrency])
  useEffect(() => {
    if (!!pageneshn) {
      setLoader(true);
      dispatch(payablesFlowactions.getApipayment(`/v4/get-payables/${selectedCurrency}?page=${pageneshn}&limit=${selectedLimit}`, getPayables) as any);
      setIsEditPayeeApiCalled(false);
    }
  }, [pageneshn, selectedLimit, isEditPayeeApiCalled]);

  const getEditedPayables = (data: any) => {
    setLoader(false);
    setPayablesData(data);
    setTotalCount(data.total);

    if (data?.data?.length < 1) {
      setAreBillsAvailable(`No bills available according to selected Currency`);
    } else {
      setAreBillsAvailable("");

      const reSelectedBills = data.data.filter((bill: any) => selectedBillIds.includes(bill.id));
      setFormik((prevFormik: any) => ({
        ...prevFormik,
        counterpart: reSelectedBills,
      }));
    }
  };
  useEffect(() => {
    if (payablesData?.data && selectedBillIds.length > 0) {
      const reSelectedBills = payablesData.data.filter((bill: any) => selectedBillIds.includes(bill.id));
      console.log("reSelectedBills", reSelectedBills)
      setFormik((prevFormik: any) => ({
        ...prevFormik,
        payment_details: reSelectedBills,
        counterpart: reSelectedBills,
        selectedBills: reSelectedBills?.map((Cbill: any) => {
          const existingBill = formik?.selectedBills?.find((b: any) => b.id === Cbill.id);

          return {
            ...(existingBill || {}),
            id: Cbill?.id,
            document_id: Cbill.document_id,
            counterpart_name: Cbill.counterpart_name,
            due_date: Cbill.due_date,
            invoice_date: Cbill.invoice_date,
            invoice_record: Cbill.invoice_record,
            created_at: Cbill.created_at,
            created_by: Cbill.created_by,
            issued_at: Cbill.issued_at,
            payable_id: Cbill.payable_id,
            status: Cbill.status,
            total: Cbill.total,
            subtotal: Cbill.subtotal,
            fees: existingBill?.fees || 0,
            balance: existingBill?.balance || 0,
            amount: Cbill?.amount,
            team: Cbill?.teams,
            vat: Cbill?.vat,
            currency: Cbill?.currency,
            ISCHECK: existingBill?.ISCHECK || false,
            country_id: existingBill?.country_id || null,
            counterpart_info: {
              ...(existingBill?.counterpart_info || {}),
              id: Cbill.entity_counterpart.id,
              name: Cbill.entity_counterpart.name,
              counterpart_id: Cbill.entity_counterpart.id,
              counterpart_name: Cbill.entity_counterpart.name,
              counterpart_type: Cbill.entity_counterpart.type,
              entity_id: Cbill.entity_counterpart.entity_id,
              is_customer: Cbill.entity_counterpart.is_customer,
              monite_counterpart_id: Cbill.entity_counterpart.monite_counterpart_id,
              monite_entity_id: Cbill.entity_counterpart.monite_entity_id,
              notify_email: Cbill.entity_counterpart.notify_email,
              notify_phone_number: Cbill.entity_counterpart.notify_phone_number,
              other_payee_accounts: Cbill?.entity_counterpart?.other_payee_accounts,
              recent_payee_account: Cbill?.entity_counterpart?.recent_payee_account,
              till_number: Cbill?.entity_counterpart?.till_number,
              account_number: Cbill?.entity_counterpart?.account_number,
            },
            bulk_payment: {
              id: Cbill?.bulk_payment?.id,
              categories: Cbill?.bulk_payment?.categories,
            }
          };
        }),
      }));
    }
  }, [payablesData, selectedBillIds]);

  useEffect(() => {
    if (isEditPayeeApiCalled) {
      const selectedIds = formik.counterpart.map((bill: any) => bill.id);
      setSelectedBillIds(selectedIds);

      setLoader(true);
      dispatch(payablesFlowactions.getApipayment(`/v4/get-payables/${selectedCurrency}?page=${pageneshn}&limit=${selectedLimit}`, getEditedPayables) as any);
      setIsEditPayeeApiCalled(false);
    }
  }, [isEditPayeeApiCalled]);


  const getBank = (data: any) => {
    setBankData(data)
  }
  const getTagName = (data: any) => {
    setCategoriesData(data)
  }
  const getTeam = (data: any) => {
    setTeamData(data)
  }
  useEffect(() => {
    if (render) {
      dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
      dispatch(payablesFlowactions.getApipayment("/counterparts/get-tags", getTagName) as any);
      dispatch(payablesFlowactions.getApipayment("/bank/get-pesalink-bank-list", getBank) as any);
      dispatch(payablesFlowactions.getApipayment("/team/get-team", getTeam) as any);
      setRender(false)
    }
  }, [render]);
  useEffect(() => {
    if (time > 0 && enableButton == false) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time, enableButton]);

  const Otpres = (data: any) => {
    console.log(data.success, "otpresdata")
    if (data.success == true) {
      setEnableButton(false)
      dispatch(userActions.getOtp('') as any)
      stepupdatefun(4);
      setUseLayout("excludenavlayout");
      setLoader(false)
      setTime(120);
      setTimeout(() => {
        setEnableButton(true)
      }, 120000)
    }

  }

  const Submitsetp3or4 = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/get-otp`, Otpres) as any);
  }

  // useEffect(() => {
  //   if (Step === 5) {    
  //     const timer = setTimeout(() => {
  //       stepupdatefun(6);
  //       setIsGoBackHidden(true)
  //       setUseLayout("fullscreenlayout")
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [Step, stepupdatefun]);

  const UpdetPayee = (id: any) => {
    setUpdetPayeeId(id);
    // setFormik((prevFormik: any) => ({
    //   ...prevFormik,
    //   counterpart: [id],
    // }));
  };

  ///// Single Bill Payment Status APi Response handler just single mean one bill

  const SingleBillPaymentStatusRES = (data: any) => {
    if (data?.data?.status == "pending") {
      if (!!data?.data?.transaction_id) {
        setTimeout(() => {
          setLoaderStatus("waiting")
        }, 120000)
        setTimeout(() => {
          SinglePaymentStatusApi(data?.data?.transaction_id)
        }, 2000)
      }
    } else if (data?.data?.status == "failed") {
      setLoaderStatus("failed")
    } else if (data?.data?.status == "success") {
      // setPaymentApiResdata(data) 
      setSinglePaymentTransactionSuccessData(data.data)
      setLoaderStatus("success")
      setTimeout(() => {
        setSingleTransactionDetailsReload(true)
      }, 3000)
    }
  }

  ///// Bulk Payment Status Api Response handler just for bulk mean more than one bill
  const BulkPaymentStatusApiRes = (data: any) => {
    console.log(data, "batch_status");

    if (data.batch_status == false) {
      setTimeout(() => {
        setLoaderStatus("waiting")
      }, 120000)
      setTimeout(() => {
        BulkPaymentStatusApi()
      }, 3000)


    } else if (data.batch_status == true) {
      setPaymentApiResdata(data)
      setLoaderStatus("")
    } else {
      setLoaderStatus("failed")
    }
  }
  console.log(paymentApiResdata, "billpaymentresdata")
  //// Single Paymnet Status Api Caller function just for single mean one bill
  const SinglePaymentStatusApi = (reference: any) => {
    var body = {
      data: [
        {
          id: reference
        }
      ]
    }
    dispatch(payablesFlowactions.PostPaymentfull(`/v4/get-bulk-payments-status`, body, SingleBillPaymentStatusRES, setLoaderPayment, false) as any)
  }

  //// Bulk Paymnet Status Api Caller function just for bulk mean more than one bill
  const BulkPaymentStatusApi = () => {
    var paymentArray: any = []
    for (let index = 0; index < formik.apiUpdatedBills.length; index++) {
      const element = formik.apiUpdatedBills[index];
      var objectPaymentArray = {
        id: element.id
      }
      paymentArray.push(objectPaymentArray)
    }
    var body = {
      data: paymentArray
    }

    dispatch(payablesFlowactions.PostPaymentfull(`/v4/get-bulk-payments-status`, body,
      Array.isArray(body.data) && body.data.length > 1 ? BulkPaymentStatusApiRes : SingleBillPaymentStatusRES
      , setLoaderPayment, false) as any);
  }

  ///// Single Payment Response handler just single mean one bill
  const SinglePaymentRes = (data: any, status: any) => {
    if (status == 'success') {
      if (formik.currency == 'USD' || formik.payment_channel == "rtgs" || formik.payment_channel == "eft") {
        setLoaderStatus("success_USD")
      } else {
        SinglePaymentStatusApi(data?.data?.reference)
      }
    } else {
      setLoaderStatus('failed')
    }
  }
  ///// Bulk Payment Response handler just bulk mean more than one bills
  const BulkPaymentRes = (data: any, status: any) => {
    if (status == "success") {
      if (formik.currency == 'USD' || formik.payment_channel == "rtgs" || formik.payment_channel == "eft") {
        setLoaderStatus("success_USD")
      } else {
        BulkPaymentStatusApi()
      }
    } else {
      setLoaderStatus('failed')
    }
  }

  ///// Retry Bulk Payment for All Singe bill && bulk Bill
  const retryBulkPayment = (otp: any) => {
    console.log(selectedRetryIds, "retryBulkPayment")
    var retryArray: any = []
    for (let index = 0; index < selectedRetryIds.length; index++) {
      const element = selectedRetryIds[index];
      var objectRetryArray = {
        id: element
      }
      retryArray.push(objectRetryArray)
    }
    const retryBody = {
      "otp": otp,
      "currency": formik.currency,
      "country_id": formik.country_id,
      "data": retryArray
    }
    setLoaderStatus("pending")
    dispatch(payablesFlowactions.PostPaymentfull("/v4/bulk-payments", retryBody,
      Array.isArray(retryBody.data) && retryBody.data.length > 1 ? BulkPaymentRes : SinglePaymentRes
      , setLoaderPayment, false) as any)
  }

  /////Bulk Payment for All Singe bill && bulk Bill
  const BulkPayment = (otp: any) => {
    var paymentArray: any = []
    for (let index = 0; index < formik.apiUpdatedBills.length; index++) {
      const element = formik.apiUpdatedBills[index];
      var objectPaymentArray = {
        id: element.id
      }
      paymentArray.push(objectPaymentArray)
    }
    setPayloadBulk(paymentArray)
    const body = {
      "otp": otp, // Newly added
      "currency": formik.currency, // Newly added
      "country_id": formik.country_id, // Newly added
      "data": paymentArray
    }
    setLoaderStatus("pending")
    dispatch(payablesFlowactions.PostPaymentfull("/v4/bulk-payments", body,
      Array.isArray(body.data) && body.data.length > 1 ? BulkPaymentRes : SinglePaymentRes
      , setLoaderPayment, false) as any)

  }
  useEffect(() => {
    console.log("balance value", formik.balance, formik.totalAmount)
    if (formik.balance > 0) {
      if (formik.totalAmount > formik.balance) {
        setErrorMsg("You have insufficient balance.")
      }
    }
  }, [formik])

  useEffect(() => {
    console.log(formik, "api_balance")
  }, [formik])

  ///// Retry Payment Otp Response
  const retryPaymentOtpRes = (data: any) => {

    if (data.success == true) {
      setTransaction_detailReload(false)
      setEnableButton(false)
      setLoader(false)
      stepupdatefun(4)
      setUseLayout("popuplayout")
      setTime(120);
      setTimeout(() => {
        setEnableButton(true)
      }, 120000)
    }

  }

  ////// Retry Payment Otp
  const retryPaymentOtp = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/get-otp`, retryPaymentOtpRes) as any);
  }

  useEffect(() => {
    console.log(singlePaymentTransactionSuccessData, "singlePaymentTransactionSuccessData")
  }, [singlePaymentTransactionSuccessData])


  return (
    <Box>

      {
        Step == 0 ?
          <Step1
            setGoBackButton={setGoBackButton}
            setIsEditPayeeApiCalled={setIsEditPayeeApiCalled}
            setUpdetPayeeId={setUpdetPayeeId}
            setCountriyIdEDIT={setCountriyIdEDIT}
            CountriyIdEDIT={CountriyIdEDIT}
            Currencyedit={Currencyedit}
            setCurrencyedit={setCurrencyedit}
            UpdetPayeeId={UpdetPayeeId}
            selectedCurrency={SelectCurrency}
            loader={loader}
            bankData={bankData}
            searchPayee={searchPayee}
            setSelectedUser={setSelectedUser}
            setSearchPayee={setSearchPayee}
            SelectFleg={selectedFlag}
            getPayees={getPayees}
            setIsAddPayeeComponent={setIsAddPayeeComponent}
            isAddPayeeComponent={isAddPayeeComponent}
            areBillsAvailable={areBillsAvailable}
            selectedLimit={selectedLimit}
            setSelectedLimit={setSelectedLimit}
            selected={selected}
            handleClick={handleClick}
            totalCount={totalCount}
            setPagelist={setPagelist}
            remove={remove}
            setRemovedata={setRemovedata}
            pageneshn={pageneshn}
            setFormik={setFormik}
            payablesData={payablesData}
            formik={formik}
            stepupdatefun={stepupdatefun}
          />
          : Step == 1 ?
            <Step2 time={time}
            setIsStepsNavHidden={setIsStepsNavHidden}
            enableButton={enableButton}
            setEnableButton={setEnableButton}
              setTime={setTime} users={users} isSchedulePayment={isSchedulePayment} setIsSchedulePayment={setIsSchedulePayment} setGoBackButton={setGoBackButton} errorsMsg={errorsMsg} setErrorMsg={setErrorMsg} selectpayees={selectedUser} setErrorMsgapi={setErrorMsgapi} errorsmsgapi={errorsmsgapi} UpdetPayee={UpdetPayee} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} setUseLayout={setUseLayout} teamData={teamData} categoriesData={categoriesData} bankList={bankData} formik={formik} setFormik={setFormik} selectedCurrency={selectedCurrency} selectedFlag={selectedFlag} stepupdatefun={stepupdatefun} />
            : Step == 2 ?
              <Step3 dataSave={dataSave} setDataSave={setDataSave} formik={formik} loader={loader} Submitsetp3or4={Submitsetp3or4} setUseLayout={setUseLayout} stepupdatefun={stepupdatefun} />
              // : Step == 3 ?
              //   <Step5 dataSave={dataSave} setUseLayout={setUseLayout} formik={formik} stepupdatefun={stepupdatefun} />

              :
              Step == 4 ?
                <Step4 setTransaction_detailReload={setTransaction_detailReload} retryBulkPayment={retryBulkPayment} selectedRetryIds={selectedRetryIds} BulkPayment={BulkPayment} formik={formik} BillPayment={BillPayment} Submitsetp3or4={Submitsetp3or4} setEnableButton={setEnableButton} setTime={setTime} enableButton={enableButton} time={time} setUseLayout={setUseLayout} stepupdatefun={stepupdatefun} />

                : Step == 5 ?
                  <Step6 loader={loader} setLoader={setLoader} notifyPayment={notifyPayment} setNotifyPayment={setNotifyPayment} singlePaymentTransactionSuccessData={singlePaymentTransactionSuccessData} setSinglePaymentTransactionSuccessData={setSinglePaymentTransactionSuccessData} setFormik={setFormik} selectedRetryIds={selectedRetryIds} setSelectedRetryIds={setSelectedRetryIds} retryPaymentOtp={retryPaymentOtp} paymentApiResdata={paymentApiResdata} loaderStatus={loaderStatus} singleTransactionDetailsReload={singleTransactionDetailsReload} transactionDetailsReload={transaction_detailReload} setTransaction_detailReload={setTransaction_detailReload} setLoaderStatus={setLoaderStatus} formik={formik} stepupdatefun={stepupdatefun} setUseLayout={setUseLayout} />
                  : Step == 6 ?
                    <Step7
                      loaderStatus={loaderStatus}
                      setTransaction_detailReload={setTransaction_detailReload}
                      formik={formik}
                      setFormik={setFormik}
                      setUseLayout={setUseLayout}
                      stepupdatefun={stepupdatefun}
                      selectedRetryIds={selectedRetryIds}
                      setSelectedRetryIds={setSelectedRetryIds}
                      retryPaymentOtp={retryPaymentOtp}
                      paymentApiResdata={paymentApiResdata}
                    />
                    : ""
      }
    </Box>
  )
}

export default BillPayment