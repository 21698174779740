import React, { createContext, useContext, useState, useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import axios from 'axios';
import { authHeader } from '../../_helpers';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [updates, setUpdates] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [payable_ids, setPayableIds] = useState([]);

    const localUser = localStorage.getItem('user');
    const userId = localUser ? JSON.parse(localUser).data.user_id : '';
     // Fetch notifications from the API
    const fetchNotifications = async () => {
        try {
            const localUser = localStorage.getItem('user');
            var userId = '';
            if (localUser != null) {
                userId = JSON.parse(localUser).data.user_id;
            } 
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', ...authHeader() },
                body: userId
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/notifications`, requestOptions);
            if (response.data.success) {
              
                setNotifications(response.data.data);
                setUnreadCount(response.data.data.filter(notification => !notification.read_at).length);
                updateLocalStorage(response.data.data); // Store in local storage
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };
    const fetchUpdates = async () => {
        try {
            const localUser = localStorage.getItem('user');
            var userId = '';
            if (localUser != null) {
                userId = JSON.parse(localUser).data.user_id;
            } 
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
                body: userId
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-features`, requestOptions);
            if (response.data.success) {
              
                const updatesList = response.data.data;
                setUpdates(updatesList);
                const unreadUpdatesCount = updatesList.length;
                setUpdateCount(unreadUpdatesCount);
                updateLocalStorageUpdates(updatesList);
            }
        } catch (error) {
            console.error('Error fetching updates:', error);
        }
    };
    useEffect(() => {
        fetchNotifications();
        fetchUpdates();
    }, []);
    useEffect(() => {
        // Initialize Pusher and Echo
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '1fd1b17aed43d7ec0784',
            cluster: 'mt1',
            forceTLS: false,
        });

    // Subscribe to the notification channels         
    window.Echo.channel('notifications.' + userId).notification((data) => {
      
        setNotifications((prev) => {
            const updatedNotifications = [data, ...prev];
            updateLocalStorage(updatedNotifications); // Update local storage
            return updatedNotifications;
        });
        setUnreadCount((prev) => prev + 1);
    });      
    window.Echo.connector.pusher.connection.bind('connected', () => {
       
    });

    window.Echo.connector.pusher.connection.bind('error', (err) => {
      console.error('Connection error:', err);
    });

    window.Echo.connector.pusher.connection.bind('state_change', (states) => {
    //    
    });

      // Subscribe to the payable id channel         
      window.Echo.channel('payables.' + userId).notification((data) => {
      
        setPayableIds((prev) => {
            const payable_ids = [data, ...prev];
           // updateLocalStorage(updatedNotifications); // If need to store them in local storage
            return payable_ids;
        });
    });  
    return () => {
       window.Echo.leaveChannel(`notifications.${userId}`);
       window.Echo.leaveChannel(`payables.${userId}`);
    };
       
  }, []);

  const markAllAsRead = async () => {
    try {
        const localUser = localStorage.getItem('user');
        var userId = '';
        if (localUser != null) {
          userId = JSON.parse(localUser).data.user_id;
        } 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
            body: userId
        }
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/read-all`, requestOptions);
        if (response.data.success) {
            const updatedNotifications = notifications.map((notification) => ({
                ...notification,
                read_at: new Date(),
            }));
            setNotifications(updatedNotifications);
            localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
            fetchUnreadCount();
        }
    } catch (error) {
        console.error('Error marking all notifications as read:', error);
    }
};


const markAsRead = async (index, notificationId) => {
  try {
      const localUser = localStorage.getItem('user');
      var userId = '';
      if (localUser != null) {
        userId = JSON.parse(localUser).data.user_id;
      } 
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...authHeader() },
          body: userId
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/read/${notificationId}`, requestOptions);
      if (response.data.success) {
          const updatedNotifications = [...notifications];
          updatedNotifications[index].read_at = new Date();
          setNotifications(updatedNotifications);
          localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
          fetchUnreadCount();
      }
  } catch (error) {
      console.error('Error marking notification as read:', error);
  }
};

//  // Fetch unread count from the API
 const fetchUnreadCount = async () => {
  try {
      const localUser = localStorage.getItem('user');
      var userId = '';
      if (localUser != null) {
        userId = JSON.parse(localUser).data.user_id;
      } 
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...authHeader() },
          body: userId
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/unread-count`, requestOptions);
      if (response.data.success) {
          setUnreadCount(response.data.data);
      }
  } catch (error) {
      console.error('Error fetching unread count:', error);
  }
};
    const updateLocalStorage = (updatedNotifications) => {
        localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
    };

    const updateLocalStorageUpdates = (updates) => {
        localStorage.setItem('updates', JSON.stringify(updates));
    };

    return (
        <NotificationContext.Provider value={{ notifications, unreadCount, markAllAsRead, markAsRead, updates, updateCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

