import { Grid, Box } from '@mui/material'
import "../MakePaymentLayout/makepaymentlayout.scss"
import { VerticalStepNav } from '../../Navigations/Navigations'
import { useEffect, useState } from 'react';

////Steps for VerticleStepNav


const MakePaymentLayout = ({ children, button, Step, stepupdatefun, steps, useLayout, isStepsNavHidden }: any) => {
    const [currentStep, setCurrentStep] = useState(0);

    // useEffect(() => {
    //     if (!!Step) {
    //         setCurrentStep(Step)
    //     }
    // }, [Step])

    console.log("currentStep", Step, steps, currentStep)
    return (
        <>
            {
                useLayout === "defaultlayout" ?
                    (
                        <Grid container className='mt-5'>
                            <Grid item md={3.2}>
                                {button}
                                <VerticalStepNav
                                    isStepsNavHidden={isStepsNavHidden}
                                    autoWidth={false}
                                    steps={steps}
                                    activeStepIndex={Step}
                                    onStepChange={setCurrentStep}
                                />
                            </Grid>
                            <Grid item md={5.5}>
                                <Box className="makepayment_frame" >
                                    {children}
                                </Box>
                            </Grid>
                            <Grid item md={3.2}>
                            </Grid>
                        </Grid>
                    ) :
                    useLayout === "popuplayout" ? (
                        <Grid container className='mt-5'>
                            <Grid item md={3}>
                                {button}

                                <VerticalStepNav
                                    isStepsNavHidden={isStepsNavHidden}
                                    autoWidth={true}
                                    steps={steps}
                                    activeStepIndex={Step}
                                    onStepChange={setCurrentStep}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Box className="makepayment_frame2">
                                    {children}
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                            </Grid>
                        </Grid>
                    ) :
                        useLayout === "wideboxlayout" ? (
                            <Grid container className='mt-5'>
                                <Grid item md={2.5}>
                                    {button}

                                    <VerticalStepNav
                                        isStepsNavHidden={isStepsNavHidden}
                                        autoWidth={true}
                                        steps={steps}
                                        activeStepIndex={Step}
                                        onStepChange={setCurrentStep}
                                    />
                                </Grid>
                                <Grid item md={8.5}>
                                    <Box className="makepayment_frame3">
                                        {children}
                                    </Box>
                                </Grid>
                                <Grid item md={2}>
                                </Grid>
                            </Grid>
                        ) :

                            useLayout === "excludenavlayout" ? (
                                <Grid container className='mt-5'>
                                    <Grid item md={2.5}>
                                        {button}
                                    </Grid>
                                    <Grid item md={7}>
                                        <Box className="makepayment_frame4">
                                            {children}
                                        </Box>
                                    </Grid>
                                    <Grid item md={2.5}>
                                    </Grid>
                                </Grid>)
                                :

                                useLayout === "fullscreenlayout" ? (
                                    <Grid container>
                                         <Grid item sm={0} md={1}>        
                                         </Grid>
                                        <Grid item md={12} > 
                                            <Box className="makepayment_frame5">
                                                {children}
                                            </Box>
                                        </Grid>  
                                        <Grid item sm={0} md={1}>
                                        </Grid>
                                    </Grid>
                                )
                                    : ""
            }
        </>
    )
}

export default MakePaymentLayout