import { Box, Typography, Avatar } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UserIcon } from '../Icons/Icons';
import "./payee_list.scss"


export const PayeeList = ({ payees, onClick,SelectFleg }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        flexWrap: 'nowrap',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }} 
      style={{ gap: "5px" , padding:"12px 0px 6px 0px"}}
    >
      {payees?.map((payee: any, index: any) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            minWidth: '100px',
            textAlign: 'center',
            cursor: 'pointer',
            height: "max-content",
          }}
          onClick={() => onClick(payee)}
        >

          <Box sx={{ position: "relative" }}>
            <Avatar sx={{ width: 45, height: 45, marginBottom: 1 }} className='avatar'>
            <UserIcon style={{ width: "20px" }} />
            </Avatar>
            <Box
              style={{
                width: 17,
                height: 17,
                borderRadius: '50%',
                position: 'absolute',
                bottom: 5,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${SelectFleg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

              }}
            ></Box>
          </Box>

          <Typography variant="body2" sx={{ marginBottom: 1, fontSize: "13px", marginTop: 1, fontFamily: "Poppins" }}>
            {payee.name}
          </Typography>

        </Box>
      ))}
    </Box>
  );
};





export const DetPayeeList = ({ payees, onClick,SelectFleg , selectedCurrency}: any) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: "column",
        overflowY: 'auto',
        gap: 2,
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        maxHeight: "320px",
        marginTop: "12px",
      }}
    >
      {payees?.map((payee: any, index: any) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            width: "100%",
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          style={{ paddingTop: "8px", paddingBottom: "8px" , cursor:"pointer"}}
          onClick={() => onClick(payee)}
        >

          <Box style={{ display: "flex", gap: "10px" }}>
            <Box sx={{ position: "relative", width: "max-content" }}>
              <Avatar sx={{ width: 45, height: 45, marginBottom: 1 }} className='avatar'>
                <UserIcon style={{ width: "20px" }} />
              </Avatar>
              <Box
              style={{
                width: 17,
                height: 17,
                borderRadius: '50%',
                position: 'absolute',
                bottom: 5,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${SelectFleg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

              }}
            ></Box>
            </Box>

            <Box>
              <Typography sx={{ fontSize: "15px", fontFamily: "Poppins", marginBottom: "2px" }}>
                {payee.name}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#6D6B6B", fontFamily: "Poppins" }}>
              {payee.payee_account?.bank_account_number ? `${selectedCurrency} account ending in ${payee.payee_account.bank_account_number.slice(-4)}` :
                payee.payee_account?.paybill_number ? `${selectedCurrency} account ending in ${payee.payee_account.paybill_number.slice(-4)}` :
                  payee.payee_account?.till_number ? `${selectedCurrency} account ending in ${payee.payee_account.till_number.slice(-4)}` :
                  payee.payee_account?.phone_number?`${selectedCurrency} account ending in ${payee.payee_account.phone_number.slice(-4)}` :
                  payee.payee_account?.account_number?`${selectedCurrency} account ending in ${payee.payee_account.account_number.slice(-4)}` :

                    `No ${selectedCurrency} account found`}
              </Typography>
            </Box>
          </Box>

          <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />


        </Box>
      ))}
    </Box>
  );
};
















