import { Box } from '@mui/material'
import SuccessComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Success/Success'
import ProgressComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress'
import PaymentFailed from '../../../../../../../ui/ReuseableComponents/Notifications/FailedPayment/PaymentFailed'
import Waiting from '../../../../../../../ui/ReuseableComponents/Notifications/Waiting/Waiting'
import Payment_success from '../../../../../../../ui/USD_Payment_success/Payment_success'
import Bulk_payment_summary from '../../../../../../../ui/ReuseableComponents/BulkPaymentSummary/BulkPaymentSummary'
import { MakePaymentTransactionDetails } from '../../../../../../../ui/TransactionDetails/MakePaymentTransactionDetails'
import { NotifyPayment } from '../../../../../../../ui/ReuseableComponents/Notifications/NotifyPayment/NotifyPayment'
import Step5 from '../../../ExpressPayment/Components/Step5/Step5' 
import './step6.scss'


function Step6({loader , setLoader , setNotifyPayment, notifyPayment, singlePaymentTransactionSuccessData, singleTransactionDetailsReload, selectedRetryIds, setSelectedRetryIds, retryPaymentOtp, paymentApiResdata, loaderStatus, setLoaderStatus, stepupdatefun, transactionDetailsReload, setUseLayout, formik, setFormik, setSinglePaymentTransactionSuccessData , setTransaction_detailReload }: any) {
    const onSave2 = () => {
        setSinglePaymentTransactionSuccessData(false)
        setNotifyPayment(true)
    }
    return (
        <Box style={{ maxWidth: "100%" }} className="bill_step6_container">
            {notifyPayment ? (
                <NotifyPayment 
                    loader={loader}
                    setLoader={setLoader}
                    data={singlePaymentTransactionSuccessData} 
                    counterpart={formik?.apiUpdatedBills[0]?.entity_counterpart} 
                    type={formik?.apiUpdatedBills[0]?.payment_channel}    
                />
            ) : singleTransactionDetailsReload ? (
                <MakePaymentTransactionDetails
                    data={singlePaymentTransactionSuccessData}
                    type="payout"
                    onSave={() => {
                        setUseLayout("defaultlayout");
                        stepupdatefun(0);
                        setFormik({
                            totalAmount: 0,
                            selectedBills: [],
                            counterpart: [],
                            currency: "KES",
                            country_id: "1",
                            apiUpdatedBills: [],
                            balance: 0,
                            methoadSelect: [],
                            grossAmount: 0,
                            transactionAmount: 0,
                            totalFees: 0,
                            "payment_details": [],
                        });
                    }}
                    onSave2={onSave2}
                />
            ) : transactionDetailsReload ? (
                <Bulk_payment_summary
                    setTransaction_detailReload={setTransaction_detailReload} 
                    formik={formik} 
                    setFormik={setFormik} 
                    setUseLayout={setUseLayout} 
                    stepupdatefun={stepupdatefun} 
                    selectedRetryIds={selectedRetryIds} 
                    setSelectedRetryIds={setSelectedRetryIds} 
                    retryPaymentOtp={retryPaymentOtp} 
                    paymentApiResdata={paymentApiResdata} 
                />
            ) : (
                <>
                    {loaderStatus == "success_USD" ? (
                        <Payment_success />
                    ) : loaderStatus === "pending" ? (
                        <ProgressComponent
                            text="Your transaction is being processed. Please do not navigate away from this screen" 
                        />
                    ) : loaderStatus === "success" ? (
                        <SuccessComponent heading="Transaction successful" />
                    ) : loaderStatus === "failed" ? (
                        <PaymentFailed
                            heading="Transaction failed"
                            text="We apologise as we were not able to process your payment request. Please try again later."
                            buttonText="Close"
                            onClick={() => { window.location.href = '/balance' }} 
                        />
                    ) : loaderStatus === "waiting" ? (
                        <Waiting
                            heading="Transaction in progress"
                            text1="The payment is still being processed. We request for your patience as we work to complete the process."
                            text2="In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions."
                            onClick={() => { window.location.href = '/balance' }}
                            buttonText="Close"
                        />
                    ) : null}
                </>
            )}
        </Box>
    )
}

export default Step6