import { Avatar, Box, Checkbox, Typography } from "@mui/material";
import "./step1.scss";
import { DefaultBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import { Pagination, SelectBoxPagination } from "../../../../../../../ui/pagination";
import { TheSelectBox } from "../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs";
import { CustomSelectBox } from "../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs";
import { SkeletonSelectBox } from "../../../../../Skeleton/Skeleton";
import { BillIcon } from "../../../../../Menu/icon";
import Step1AddNewPayeeComponent from "../../../ExpressPayment/Components/Step1/Components/AddNewPayee";
import { payablesFlowactions } from "../../../../../../../../_actions";
import { useDispatch } from 'react-redux'
import { UserIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";
import { useEffect, useState } from "react";

const options = [
    {
        name: 10
    },
    {
        name: 25
    },
    {
        name: 50
    },
    {
        name: 75
    },
]


const Step1 = ({
    setIsEditPayeeApiCalled,
    setIsAddPayeeComponent,
    isAddPayeeComponent,
    getPayees, SelectFleg,
    setSearchPayee,
    searchPayee,
    setSelectedUser,
    bankData,
    setCurrencyedit,
    Currencyedit,
    setCountriyIdEDIT,
    CountriyIdEDIT,
    setUpdetPayeeId,
    UpdetPayeeId,
    selectedCurrency,
    areBillsAvailable,
    loader,
    handleClick,
    selected,
    totalCount,
    pageneshn,
    setRemovedata,
    remove,
    setPagelist,
    setFormik,
    payablesData,
    payables,
    formik,
    stepupdatefun,
    selectedLimit,
    setSelectedLimit,
    setGoBackButton
}: any) => {
    const[renderBack,setRenderBack]=useState(true)
    const dispatch = useDispatch()
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const handleCheckboxClick = (payee: any) => {
        try {
            const selectedPayees = formik.counterpart || formik.selectedBills || [];
            const isSelected = selectedPayees.some((selected: any) => selected.id === payee.id);

            const updatedPayees = isSelected
                ? selectedPayees.filter((selected: any) => selected.id !== payee.id)
                : [...selectedPayees, payee];
    
            const billObject = {
                id: payee?.id,
                bulk_payable: payee.bulk_payable,
                document_id: payee.document_id,
                counterpart_name: payee.counterpart_name,
                counterpart_id: payee.counterpart_id,
                due_date: payee.due_date,
                invoice_date: payee.invoice_date,
                invoice_record: payee.invoice_record,
                created_at: payee.created_at,
                created_by: payee.created_by,
                issued_at: payee.issued_at,
                payable_id: payee.payable_id,
                status: payee.status,
                total: payee.total,
                subtotal: payee.subtotal,
                fees: 0,
                balance: 0,
                amount: payee?.amount / 100,
                team: payee?.teams,
                vat: payee?.vat,
                currency: payee?.currency,
                ISCHECK: true, 
                country_id: null,
                payment_channel: "",
                correspondent: "",
                method_title: "",
                method_no: "",
                paybill_number: '',
                paybill_account_number: '',
                payment_method: '',
                type: '',
                bank_name: '',
                bank_code: '',
                bank_account_number: '',
                apierror: "",
                counterpart_info: {
                    ...payee.entity_counterpart,  
                    counterpart_id: payee.entity_counterpart.id,
                    counterpart_name: payee.entity_counterpart.name,
                },
                entity_counterpart: payee.entity_counterpart,
                bulk_payment: {
                    id: payee?.bulk_payment?.id,
                    categories: payee?.bulk_payment?.categories,
                }
            };

            setFormik((prevState : any) => ({     
                ...prevState, 
                counterpart: updatedPayees,   
                selectedBills: isSelected 
                    ? prevState.selectedBills.filter((bill: any) => bill.id !== payee.id)
                    : [...(prevState.selectedBills || []), billObject],
            }));
        } catch (error) {
            console.error('checkbox handling', error);
            setFormik(formik);
        }
    };



    const handleLimitChange = (event: any) => {
        setSelectedLimit(event.target.value);
    };
    const UpdateSeup = (payee: any) => {
        // formik.setFieldValue('counterpart', payee)
        console.log(payee, "payeechecker")

        dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payee/${payee?.monite_counterpart_id}/${formik?.currency}/${formik?.country_id}`, (data: any) => {
            if (data) {
                setSelectedUser(data);
                // data.counterpart=data
                console.log(data, "according to monite id");
                setTimeout(() => {
                    stepupdatefun(1)
                }, 2000)
            }
        }) as any);
    }
  useEffect(() => {
    if (renderBack == true) {
      setRenderBack(false)
      setGoBackButton('')
    }
  }, [renderBack])
    return (
        <Box
        //  ref={suggestionRef}
        >
            {
                isAddPayeeComponent === false ? <>
                    <Box className="step1_container">
                        <Typography className="bills_heading">Bills</Typography>
                        <SkeletonSelectBox
                            style={{ marginTop: "15px" }}
                            width={"100%"}
                            height={"600px"}
                            loader={loader}
                            element={
                                <Box>
                                    {
                                        areBillsAvailable ? <Box className="no_bill_found">
                                            <Typography className="no_bill_found_text">
                                                {areBillsAvailable}
                                            </Typography>
                                        </Box>
                                            :      
                                            <Box>
                                                <Box>
                                                    <Box className="d-flex flex-column gap-4 pt-4 pb-4">
                                                        {payablesData?.data?.map((payee: any, index: number) => {
                                                            const isItemSelected = isSelected(payee.id);
                                                            return (
                                                                <Box className="d-flex justify-content-between align-items-center" key={payee.id}>
                                                                    <Box className="d-flex gap-2 align-items-center" style={{ width: "70%" }}>
                                                                        <Box>
                                                                            <Checkbox
                                                                                style={{ padding: "0px 0px 0px 0px" }}
                                                                                icon={<img src="/Checkbox.svg" />} 
                                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                                checked={formik.selectedBills?.some((selected: any) => selected.id === payee.id)}
                                                                                color="primary"
                                                                                onClick={() => handleCheckboxClick(payee)}
                                                                            />
                                                                        </Box>
                                                                        <Box className="d-flex flex-row gap-2 align-items-center">
                                                                            <Box sx={{ position: "relative", width: "max-content" }}>
                                                                                <Avatar sx={{ width: 45, height: 45}} className='avatar'>
                                                                                    <UserIcon style={{ width: "20px" }} />
                                                                                </Avatar>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography className="payee_name">{payee.counterpart_name}</Typography>
                                                                                <Typography className="bill_number">
                                                                                    {payee.entity_counterpart?.recent_payee_account?.bank_account_number ? `${selectedCurrency} account ending in ${payee.entity_counterpart?.recent_payee_account.bank_account_number.slice(-4)}` :
                                                                                        payee.entity_counterpart?.recent_payee_account?.paybill_number ? `${selectedCurrency} account ending in ${payee.entity_counterpart?.recent_payee_account.paybill_number.slice(-4)}` :
                                                                                            payee.entity_counterpart?.recent_payee_account?.till_number ? `${selectedCurrency} account ending in ${payee.entity_counterpart?.recent_payee_account.till_number.slice(-4)}` :
                                                                                                `No ${selectedCurrency} account found`}
                                                                                </Typography>
                                                                            </Box>

                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="d-flex flex-column text-end" style={{ width: "30%" }}>
                                                                        <Typography className="amount">{payee.currency} {payee.amount / 100}</Typography>
                                                                        <Typography className="bill_number">{payee.document_id}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                </Box>
                                                <Box className="d-flex justify-content-end w-100 mt-3">
                                                    <SelectBoxPagination options={options} selectedLimit={selectedLimit} handleLimitChange={handleLimitChange} remove={remove} pageneshn={pageneshn} setPagelist={setPagelist} setRemovedata={setRemovedata} totalCount={totalCount} />
                                                </Box>
                                                <Box className="mt-5">
                                                    <DefaultBtn
                                                        text="Continue"
                                                        disabled={!(formik.selectedBills && formik.selectedBills.length > 0)}
                                                        style={{ width: "100%" }}
                                                        onClick={() => stepupdatefun(1)}
                                                    />
                                                </Box>
                                            </Box>

                                    }
                                </Box>
                            }
                        />
                    </Box></> : <Box>
                    <Step1AddNewPayeeComponent
                        setIsEditPayeeApiCalled={setIsEditPayeeApiCalled}
                        setUpdetPayeeId={setUpdetPayeeId}
                        stepupdatefun={stepupdatefun}
                        UpdateSeup={UpdateSeup}
                        setCountriyIdEDIT={setCountriyIdEDIT}
                        CountriyIdEDIT={CountriyIdEDIT}
                        Currencyedit={Currencyedit}
                        setCurrencyedit={setCurrencyedit}
                        UpdetPayeeId={UpdetPayeeId}
                        bankData={bankData}   
                        isAddPayeeComponent={isAddPayeeComponent}
                        setIsAddPayeeComponent={setIsAddPayeeComponent} />
                </Box>
            }
        </Box>
    );
};

export default Step1;
