import { Box, Typography } from "@mui/material"
import './waiting.scss'
import { DefaultBtn } from "../../Buttons/Buttons"

const Waiting = ({heading , text1 , text2 , onClick, buttonText} : any) => {
    return (
        <Box className="waiting_container" style={{ borderRadius: "8px", background: "#FFF", width: "100%", minHeight: "35vh", alignItems: 'center', display: "flex", justifyContent: "center" }}>
            <Box>
                <Box className="waiting_heading text-center">{heading}</Box>

                <Box style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
                    <Typography className="waiting_text1">{text1}</Typography>
                    <Typography className="waiting_text2">{text2}</Typography>
                    <Box style={{ display: "flex", justifyContent: "center" }} className="mt-2">
                        <DefaultBtn text={buttonText} style={{ marginTop: "13px" , width: "80px" , textAlign: "center" }} onClick={onClick}/>
                    </Box>
                </Box>

            </Box>

        </Box>
    )
}

export default Waiting