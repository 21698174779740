import "./editfileds.scss"
import { CustomInput } from "../../Inputs/Inputs"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { DefaultBtn, LightBtn } from "../../Buttons/Buttons"
import CloseIcon from '@mui/icons-material/Close';
import { TheSelectBox } from "../../SelectBoxes/SelectBoxs";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";

const EditFields = ({ onClose, onClick, categoriesData, formik, setFormik, teamData, heading, editPayeeId }: any) => {

    console.log("edit pop up", formik)
    const vatList = [
        {
            name: "Exempt",
            value: "Exempt"
        }, {
            name: "0%",
            value: 0
        },
        {
            name: "8%",
            value: 8
        }, {
            name: "16%",
            value: 16
        }
    ]

    const handleFormikChange = (updatedData: any) => {
        setEditData((prev: any) => ({ ...prev, ...updatedData }));
    };

    // State to store form changes before updating formik
    const [editData, setEditData] = useState(formik);

    console.log("edited data ", editData)

    // Update formik when the user clicks Continue
    const handleContinue = () => {
        setFormik((prevFormik: any) => {
            const updatedBills = prevFormik.UploadedBulk.map((b: any) =>
                b.id === editPayeeId ? { ...b, ...editData } : b
            );
            return { ...prevFormik, UploadedBulk: updatedBills };
        });
        onClose();
    };

    return (
        <Box className="fields_container pb-4">

            <Box className="d-flex flex-column gap-3 fileds_box" style={{overflow:"visible"}}>
                <Box>
                    <Typography className="mt-2 mb-2 edit_heading">{heading}</Typography>
                </Box>
                {/* <Box className="paydata_item d-flex justify-content-between align-items-center">
                    <Typography className='paydata_title'>Fees</Typography>
                    <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                        <Typography className="info">{formik?.Fees / 100} {formik.currency}</Typography>
                        <ArrowForwardIosIcon className="arrowicon" style={{ visibility: "hidden" }} />
                    </Box>
                </Box> */} 
                <Box>
                    <Box display={"flex"} alignItems={"end"} justifyContent={"space-between"}>
                        <Typography className="label mb-2 required_field">Select Category</Typography>
                    </Box>
                    <Autocomplete
                        multiple
                        disablePortal
                        options={categoriesData}
                        getOptionLabel={(option: any) => option.name}
                        value={editData.categories || []} 
                        onChange={(event, newValue) => handleFormikChange({ categories: newValue })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formik?.categories?.length === 0 ? "Select Category" : ""}
                            />
                        )} 
                        sx={{
                            '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                padding: '5px 11px',
                                minHeight: '48px',
                                maxHeight: '48px',
                                fontSize: "12px",
                                '&.Mui-focused': {
                                    borderColor: "#015241",
                                    boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                                },
                                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                    padding: "0px !important",
                                    color: "#6D6B6B !important"
                                }
                            },
                        }}
                    />
                    {/* <Autocomplete
                        multiple
                        disablePortal
                        options={categoriesData}
                        getOptionLabel={(option: any) => option.name}
                        value={formik?.categories || []}
                        onChange={(event: any, newValue: any) => handleFormikChange({ categories: newValue })}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formik?.categories?.length === 0 ? "Select Category" : ""}
                            />
                        )}
                        sx={{
                            '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                padding: '5px 11px',
                                minHeight: '48px',
                                maxHeight: '48px',
                                fontSize: "12px",
                                '&.Mui-focused': {
                                    borderColor: "#015241",
                                    boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                                },
                                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                    padding: "0px !important",
                                    color: "#6D6B6B !important"
                                }
                            },
                        }}
                    /> */}
                </Box>
                <Box>
                    <Typography className="label mb-2">Select Team</Typography>
                    <Autocomplete
                        multiple
                        disablePortal
                        options={teamData?.map((category: any) => category.name)}
                        value={formik?.teams}
                        // onChange={(event: any, newValue: any) => {
                        //     setFormik({ ...formik, teams: newValue });
                        // }}
                        onChange={(event: any, newValue: any) => handleFormikChange({ teams: newValue })}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formik?.teams?.length === 0 ? "Select Team" : ""}
                            />
                        )}
                        sx={{
                            '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                padding: '5px 11px',
                                minHeight: '48px',
                                maxHeight: '48px',
                                fontSize: "12px",
                                '&.Mui-focused': {
                                    borderColor: "#015241",
                                    boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                                },
                                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                    padding: "0px !important",
                                    color: "#6D6B6B !important"
                                },

                            },
                        }}
                    />
                </Box>
                <Box>

                    <Typography className='label mb-2'>Select Vat %</Typography>

                    <Autocomplete
                        // multiple
                        disablePortal

                        // options={vatList.map((vat) => ({ label: vat.name, value: vat.value }))}
                        options={vatList?.map((vat: any) => vat.value)}
                        value={formik.vat}
                        onChange={(event: any, newValue: any) => handleFormikChange({ vat: newValue })}

                        // onChange={(event: any, newValue: any) => handleFormikChange(newValue?.value)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formik?.vat?.length === 0 ? "Select Vat%" : ""}
                            />
                        )}
                        sx={{
                            '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                padding: '5px 11px',
                                minHeight: '48px',
                                maxHeight: '48px',
                                fontSize: "12px",
                                '&.Mui-focused': {
                                    borderColor: "#015241",
                                    boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                                },
                                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                    padding: "0px !important",
                                    color: "#6D6B6B !important"
                                }
                            },
                        }}
                    />



                </Box>
                <Box >
                    <Typography className='label'>Enter description</Typography>
                    <CustomInput
                        value={formik?.description}
                        onChange={(e: any) => handleFormikChange({ description: e.target.value })}
                        // onChange={(e: any) => setFormik({ ...formik, description: e.target.value })}
                        placeholder="Enter description"
                        style={{ padding: "5px 11px", borderRadius: "8px", color: "#6D6B6B !important" }}
                    />
                </Box>
            </Box>
            <Box className="d-flex gap-2 pb-4" sx={{ justifyContent: "end", marginTop: "30px", borderBottom: "1px solid #DDDDDD" }} >
                <DefaultBtn onClick={handleContinue} style={{ width: "100%" }} text="Continue" />
            </Box>
        </Box>
    )
}

export default EditFields