import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from "formik";
import { AuthorizeTransaction } from "../../../../../../../ui/ReuseableComponents/AuthorizeTransaction/AuthorizeTransaction";

const Step4 = ({setTransaction_detailReload, retryBulkPayment , selectedRetryIds , BulkPayment, formik, BillPayment, Submitsetp3or4, setUseLayout, stepupdatefun, setEnableButton, setTime, enableButton, time, }: any) => {
  const [filedValue, setFiledValue] = useState({
    Otp: ""
  })
  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(6, 'OTP must be exactly 6 digits')
      .required('OTP is required'),
  });

  return (
    <Formik
      initialValues={filedValue}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if(selectedRetryIds.length > 0){
          retryBulkPayment(values.Otp)
        }
        else if (formik.apiUpdatedBills.length > 0) {    
          BulkPayment(values.Otp)
        }

        if(formik.apiUpdatedBills.length === 1){
          stepupdatefun(5)
          setUseLayout("excludenavlayout")
        }
        else if (formik.apiUpdatedBills.length > 1){
          stepupdatefun(6)
          setUseLayout("fullscreenlayout");
        }

      }}  
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} > 
          <AuthorizeTransaction
            time={time}
            enableButton={enableButton}
            formik={formik}
            onCancel={() => {
              stepupdatefun(2)
              setUseLayout("wideboxlayout")
              // setEnableButton(true)
              // setTime(0)
            }}
            onResendOtp={() => { Submitsetp3or4() }}
            onClick={() => { }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default Step4