import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Avatar, Checkbox, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DynamicSelectComponent, InputSelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomInput, InputDropBox } from '../../../../../../../ui/ReuseableComponents/Inputs/Inputs';
import './step2.scss'
import { DefaultBtn, DefaultGoBackBtn, LightBtn } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons';
import { isDataView } from 'util/types';
import AddNewPayee from '../Step1/Components/AddNewPayee';
import AddPaymentMethod from '../../../../../../../ui/ReuseableComponents/Payment/AddPaymentMethod';
import { TheSelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import { Select, MenuItem, ListItemText, InputLabel, FormControl, Chip, OutlinedInput } from '@mui/material';
import MultipleSelectCheckmarks from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CurrencySelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import { payablesFlowactions } from '../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
import { UploadIcon } from '../../../../../../../ui/ReuseableComponents/Icons/Icons';
import { handleFileUpload } from '../../../../../../../utils';
import { ErrorMessage } from '../../../../../../../ui/eroor';
import SchedulePayment from '../SchedulePayment/SchedulePayment';
import { number } from 'yup';


import TransactionExistCom from '../../../../../../../ui/ReuseableComponents/Notifications/TransactionExit/TransactionExistCom';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { ModelCategories } from './components/CreateCategories';


const vatList = [
  {
    name: "Exempt",
    value: "Exempt"
  }, {
    name: "0%",
    value: '0'
  },
  {
    name: "8%",
    value: '8'
  }, {
    name: "16%",
    value: '16'
  }
]




const Step2 = ({ setGoBackButton, getTagNameApi, setTeamData, setAmount, amount, isSchedulePayment, setIsSchedulePayment, UpdetPayee, setFormik, errorsMsgText, submitted, setSubmitted, errorsMsg, formik, payee, onBack, onNextStep, stepupdatefun, step, teamData, categoriesData, selectpayees, isAddPayeeComponent, setIsAddPayeeComponent, selectedCurrency, selectedFlag, setErrorMsgapi, errorsmsgapi, time,
  setTime,
  setEnableButton, enableButton, Submitsetp3or4, ExpressPayment, setUseLayout, setIsStepsNavHidden, users }: any) => {
  const [selectedValue, setSelectedValue] = useState<string>('default');
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedVat, setSelectedVat] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [currency, setCurrency] = useState([]);
  const [render, setRender] = useState(true)
  const [invoiceImage, setinvoiceImage] = useState<any>("");
  const [DuplicatetarModel, setDuplicatetarModel] = useState(false)
  const [DuplicatetarMsg, setDuplicatetarMsg] = useState('')
  const [SelectFleg, setSelectFleg] = useState('https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Kenya-Flag.png')
  const [fileSize, setFileSize] = useState(0);
  const [invoicefile, setinvoicefile] = useState("Upload supporting document");
  const [description, setDescription] = useState("")
  const [loader, setLoader] = useState(false)
  const [buttonDisableErrorMsg, setButtonDisableErrorMsg] = useState("")
  const [DuplicateTransaction, setDuplicateTransaction] = useState(false)
  const [field_data, setField_data] = useState('');
  const [validesan, setValidesan] = useState(false)
  const [newTeamModal, setnewTeamModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [cereteTeam_validet, setCereteTeam_validet] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [Name, setName] = useState('')
  const [Submit, setSubmit] = useState(false)
  const [renderBack, setRenderBack] = useState(true)

  const [OpenModelCategories, setOpenModelCategories] = useState(false)
  const [openStates, setOpenStates] = useState({
    isCategoryOpen: false,
    isTeamOpen: false,
    isVatOpen: false,
    isMethodOpen: false,
    isDocumentOpen: false,
    isDescriptionOpen: false,
  });


  const ModelCategoriesClose = () => {
    setOpenModelCategories(false);
    setName('')
    setSubmit(false)
    setErrorMsg('')
  }
  const CreateCategoriesRes = (data: any, status: any) => {
    setSubmit(true)
    if (status == 'success') {
      getTagNameApi()
      ModelCategoriesClose()
    } else if (status == "failed") {
      setErrorMsg(data.message);

    }

  }
  const CreateCategories = () => {
    var reqBody = {
      "name": Name
    }
    dispatch(payablesFlowactions.PostPaymentfull("/tags", reqBody, CreateCategoriesRes, setLoader, true) as any);

  }
  const getTeamRes = (data: any) => {
    setTeamData(data)
  }
  const getTeam = () => {
    dispatch(payablesFlowactions.getApipayment("/team/get-team", getTeamRes) as any);

  }
  const ResCreateTeam = (data: any, status: any) => {
    if (status == 'success') {
      setCereteTeam_validet("1")
      setField_data('');
      onCloseTeam()
      getTeam()
    } else if (status == "failed") {
      setCereteTeam_validet('2')
    }
  }
  const CreateTeam = () => {
    setValidesan(true)
    if (!!field_data) {
      var reqBody = {
        "name": field_data
      }
      dispatch(payablesFlowactions.PostPaymentfull("/team/create", reqBody, ResCreateTeam, setLoader, true) as any);
    }
  }
  const onCloseTeam = () => {
    setnewTeamModal(false);
    setField_data('')
  }
  const onCloseTransactionExist = () => {
    setDuplicatetarModel(false)
  }
  const onSubmit = () => {
    setDuplicatetarModel(false)
    stepupdatefun(2)
    setSubmitted(true)
  }


  const dispatch = useDispatch()

  const handleTeamsChange = (event: any) => {
    const { target: { value } } = event;
    setSelectedTeams(typeof value === 'string' ? value.split(',') : value);
  };



  const handleCategoryChange = (event: any) => {
    const { target: { value } } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };

  const handleVatChange = (event: any) => {
    setSelectedVat(event.target.value);
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleToggle = (key: keyof typeof openStates) => {
    setOpenStates({
      isCategoryOpen: false,
      isTeamOpen: false,
      isVatOpen: false,
      isMethodOpen: false,
      isDocumentOpen: false,
      isDescriptionOpen: false,
      [key]: !openStates[key],
    });
  };
  const handleBankAndMethod = () => {
    handleToggle('isMethodOpen');
  }

  // Function to mask the input value except last 4 digits
  const getMaskedValue = (value: string) => {
    if (!value) return '';
    const lastFourDigits = value.slice(-4);
    const maskedPart = '*'.repeat(Math.max(0, value.length - 4));
    return maskedPart + lastFourDigits;
  };

  const handleCategoryOpen = () => {
    handleToggle('isCategoryOpen');
  }
  const handleTeamOpen = () => {
    handleToggle('isTeamOpen');
    console.log(teamData)

  }
  const handleVatOpen = () => {
    handleToggle('isVatOpen');
  }
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if (element.currency == "GHS") {

      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect(() => {
    if (render) {
      setRender(false)
      dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
    }
  }, [render])

  console.log(selectpayees, "selectpayees");

  const handleImage = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileSize(file.size);
      setinvoicefile(file.name);
      const fileBase64 = await handleFileUpload(e);
      if (file.size && file.size <= 1024 * 1024 * 5) {
        setinvoiceImage(fileBase64);
        setFormik({ ...formik, invoice_record: fileBase64 })
      } else {
        setinvoiceImage('');
      }
    }
  };
  const inputRef: any = useRef(null);


  // const 
  const CheckBalanceRES = (data: any) => {
    console.log(data, "datadata852");

    if (data.success == true) {
      setFormik({ ...formik, Fees: data.data.fees })
      if (data.message == "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
        setDuplicatetarModel(true)
        setDuplicatetarMsg(data.message)

      }
      setErrorMsgapi('')

    } else if (data.success == false) {
      setErrorMsgapi(data.message)
      setFormik({ ...formik, Fees: 0 })
    }
  }
  useEffect(() => {
    if (!!formik.currency && !!formik.country_id && !!formik.type && !!formik.amount) {
      ChackBlence(formik.payment_channel, formik.correspondent, formik.paybill_number, formik.paybill_account_number, formik.bank_name, formik.bank_code, formik.bank_account_number)
    }
  }, [amount])
  useEffect(() => {
    if (!formik.amount || formik.amount == 0) {
      setOpenStates((prevState) => ({
        ...prevState,
        isMethodOpen: false,
      }));
      // setFormik({
      //   ...formik,
      //   method_title: "",
      //   method_no: "",
      //   payment_method: "",
      //   correspondent: "",
      //   payment_channel: "",
      //   type: "",
      //   paybill_number: "",
      //   paybill_account_number: "",
      //   bank_name: "",
      //   bank_code: "",
      //   bank_account_number: ""
      // })
    }
    if (!!formik.amount && !formik.method_title && formik.amount != 0) {
      setOpenStates((prevState) => ({
        ...prevState,
        isMethodOpen: true,
      }));
    }
  }, [formik.amount])

  const ChackBlence = (paymentchannel: any, correspondent: any, paybill_number: any, paybill_account_number: any, bank_name: any, bank_code: any, bank_account_number: any) => {
    console.log(correspondent, paymentchannel, "paymentchannelpaymentchannel");

    var body: any = {
      "currency": formik.currency,
      payee: formik?.counterpart?.name,
      "country_id": formik.country_id,
      "type": "pay_out", //payout, payin
      "payment_method": correspondent,
      "amount": formik.amount,
      "paymentchannel": paymentchannel
      //  paymentchannel
    }
    if (paymentchannel == "pay bill") {
      body.account_number = paybill_account_number

      body.paybill_number = paybill_number
    }
    if (paymentchannel == "pesalink") {

      body.bank_name = bank_name
      body.bank_code = bank_code
      body.account_number = bank_account_number
    }
    dispatch(payablesFlowactions.PostPaymentfull("/bank/check-balance-v3", body, CheckBalanceRES, setLoader, false) as any);
  }
  useEffect(() => {
    if (submitted && errorsMsgText.tags) {
      setOpenStates((prevState) => ({
        ...prevState,
        isCategoryOpen: true,
      }));
    }
  }, [submitted, errorsMsgText.tags]);

  console.log(errorsmsgapi, "errorsmsgapi");
  useEffect(() => {
    if (renderBack == true) {
      setRenderBack(false)
      setGoBackButton(<DefaultGoBackBtn
        // style={{ marginTop: "-60px" }}
        onClick={() => {

          setFormik({
            counterpart: {},
            currency: "KES",
            invoice_record: "",
            vat: "",
            amount: 0,
            description: "",
            tags: [],
            teams: [],
            type: '',
            country_id: "1",
            verified_account_name: "",
            Fees: 0,
            balance: "",
            payment_channel: "",
            correspondent: "",
            method_title: "",
            method_no: "",
            paybill_number: '',
            paybill_account_number: '',
            "bank_name": '',
            "bank_code": '',
            bank_account_number: '',
            date: undefined,
            time: undefined,
            payment_method: ''
          });
          setOpenStates({
            isCategoryOpen: false,
            isTeamOpen: false,
            isVatOpen: false,
            isMethodOpen: false,
            isDocumentOpen: false,
            isDescriptionOpen: false,
          });
          setinvoicefile("Upload supporting document");
          setDescription("");
          setSubmitted(false);
          stepupdatefun(0);
        }}
      />)
    }
  }, [renderBack])

  const handleFocus = () => {
    console.log('sdadasddddd');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <>
      {
        isSchedulePayment ? (
          <SchedulePayment
            users={users}
            selectedFlag={selectedFlag}
            setFormik={setFormik}
            formik={formik}
            selectedPayee={selectpayees}
            isSchedulePayment={isSchedulePayment}
            setIsSchedulePayment={setIsSchedulePayment}
            time={time}
            setTime={setTime}
            setEnableButton={setEnableButton}
            enableButton={enableButton}
            Submitsetp3or4={Submitsetp3or4}
            ExpressPayment={ExpressPayment}
            setUseLayout={setUseLayout}
            setIsStepsNavHidden={setIsStepsNavHidden}
          />

        ) : (
          <Box>

            <Box className="d-flex flex-column">
              <Typography className="step2_heading">How much are you sending?</Typography>
              <Box className="inputs_container" style={{ padding: openStates.isMethodOpen ? "30px 0px 20px 0px" : "30px 0px 45px 0px" }}>
                <Box className='dot'>
                  <label className="label">You send</label>
                  <InputSelectBox onChange={(e: any) => {
                    const value = e.target.value.replace(/[eE]/g, "");
                    console.log(value, "e.target.value");
                    setButtonDisableErrorMsg("")
                    setAmount(value)
                    // formik.amount = value
                    setFormik({ ...formik, amount: value })
                    console.log(formik, "wvformik");
                    // formik.setFieldValue("amount", value)
                  }}
                    type="number"
                    value={formik.amount}
                    placeholder="Enter amount"
                    currencyList={<Box className="d-flex gap-1 align-items-center">
                      <img src={selectedFlag} alt='' style={{ width: "22px", height: "18px", borderRadius: "50%", padding: "0px", margin: "0px" }} />
                      <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }}>{selectedCurrency}</Typography>
                    </Box>}
                  />
                  {!formik.amount && submitted == true ? (
                    <ErrorMessage>{errorsMsgText.amount}</ErrorMessage>
                  ) : null}
                </Box>
                <Box className='dot'>
                  <label className="label">{selectpayees.name}'s Payment Details</label>
                  <InputDropBox
                    readOnly={true}
                    inputValue={getMaskedValue(formik.method_no)}
                    disabled={formik.amount < 1 ? true : false}
                    style={{ color: "black" }}
                    placeholder={formik.method_no}
                    arrowForwardIcon={(<Box style={{ width: "max-content" }} className="d-flex align-items-center gap-3">
                      <Typography onClick={() => {
                        if (formik.amount > 0) {
                          handleBankAndMethod()
                        }
                      }} style={{ cursor: "pointer", fontFamily: "Poppins", fontSize: "12px" }}>{formik.method_title}</Typography>
                      <ArrowForwardIosIcon
                        style={{
                          transform: `rotate(${openStates.isMethodOpen ? 90 : 0}deg)`,
                          transition: "transform 0.3s ease",
                        }}
                        className="arrowforwardicon"
                        onClick={() => {
                          console.log(formik, "formikcheck")
                          if (formik.amount > 0) {
                            console.log(formik, "wajidformikvalues");
                            console.log(formik, "wajidformik");
                            handleBankAndMethod()
                            setButtonDisableErrorMsg("")
                          }
                          else {
                            setButtonDisableErrorMsg("Please enter the amount first")
                          }
                        }}
                      />
                    </Box>)} />
                  {(
                    buttonDisableErrorMsg ? <ErrorMessage>{buttonDisableErrorMsg}</ErrorMessage> : (!formik.type && submitted == true ? (
                      <ErrorMessage>{errorsMsgText.mothed}</ErrorMessage>
                    ) : null)
                  )}

                  <Box style={{
                    display: 'grid',
                    gridTemplateRows: openStates.isMethodOpen ? '1fr' : '0fr',
                    transition: 'grid-template-rows 0.3s ease',
                  }}>
                    <Box style={{
                      minHeight: 0,
                      overflowY: 'auto',
                      opacity: openStates.isMethodOpen ? 1 : 0,
                      visibility: openStates.isMethodOpen ? 'visible' : 'hidden',
                      transition: 'opacity 0.2s ease, visibility 0.3s ease'
                    }}>
                      <Box>
                        <AddPaymentMethod
                          UpdetPayee={UpdetPayee}
                          setIsMethodOpen={setOpenStates}
                          setFormik={setFormik}
                          ChackBlence={ChackBlence}
                          formik={formik}
                          SelectCurrency={SelectCurrency}
                          enterAmount={formik.amount}
                          setIsAddPayeeComponent={setIsAddPayeeComponent}
                          stepupdatefun={stepupdatefun}
                          selectedPayee={selectpayees}
                          payee={selectpayees}
                          onClose={() => handleToggle('isMethodOpen')}
                          step={step}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

              </Box>


              <Box className="paydata_container d-flex gap-4 flex-column">
                <Typography className="paydata_heading" style={{ fontFamily: "Poppins" }}>Paying from</Typography>
                <Box className="d-flex justify-content-between align-items-center">
                  <Box className="d-flex gap-3">
                    <Box style={{ backgroundImage: `url(${selectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "45px", height: "45px", borderRadius: "50%" }}>

                    </Box>
                    <Box className="d-flex flex-column">
                      <Typography className='bank_account'>
                        {!!users ? `${users.name} **${users.account_number.slice(-4)}` : 'Loading...'}

                      </Typography>
                      <Typography className='available_balance'>{formik.currency} {formik.balance / 100} available</Typography>
                    </Box>
                  </Box>
                  <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                    {/* <Typography className="paydata_title" style={{ cursor: "pointer", color: "#025041", fontSize: "15px" }}>Change</Typography> */}
                    {/* <ArrowForwardIosIcon className='arrowicon' /> */}
                  </Box>
                </Box>
                <Box className="paydata_item d-flex justify-content-between align-items-center">
                  <Typography className='paydata_title'>Fees</Typography>
                  <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                    <Typography className="info">{formik?.Fees / 100} {formik.currency}</Typography>
                    <ArrowForwardIosIcon className="arrowicon" style={{ visibility: "hidden" }} />
                  </Box>
                </Box>


                <Box className="payadata_item_wrapper d-flex flex-column">
                  <Box className="paydata_item d-flex justify-content-between dot">
                    <label className='paydata_title'>Category</label>
                    <Box className="d-flex gap-3 " style={{ cursor: "pointer" }}>
                      <Typography className='info' onClick={() => handleCategoryOpen()}>
                        {formik.tags.length === 0 ? "Select Category" : formik.tags.map((category: any, index: number) => (
                          <Typography key={index} style={{ color: "#025041", fontFamily: "Poppins", textAlign: "right" }}>
                            {category.name}
                          </Typography>

                        ))}

                      </Typography>
                      <ArrowForwardIosIcon className='arrowicon'
                        onClick={() => handleCategoryOpen()}
                        style={{
                          transform: `rotate(${openStates.isCategoryOpen ? 90 : 0}deg)`,
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box className="vat_box" style={{
                    display: 'grid',
                    gridTemplateRows: openStates.isCategoryOpen ? '1fr' : '0fr',
                    transition: 'grid-template-rows 0.3s ease',
                  }}>
                    <Box style={{
                      minHeight: 0,
                      // overflowY: 'auto',
                      opacity: openStates.isCategoryOpen ? 1 : 0,
                      visibility: openStates.isCategoryOpen ? 'visible' : 'hidden',
                      transition: 'opacity 0.2s ease, visibility 0.3s ease',
                    }}>

                      <Box className="mt-4">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                          <label className='label mb-2'>Select Category</label>
                          {newTeamModal == true ? (
                            <Button style={{ fontSize: "13px", textTransform: "capitalize" }} className="complete-profile__ADDBTNHHA">
                              + Add new Category
                            </Button>
                          ) : (
                            <Button
                              style={{ fontSize: "13px", textTransform: "capitalize" }}
                              className="complete-profile__ADDBTNHHA"
                              onClick={() => {
                                // setNewTeam(!newTeam);
                                setOpenModelCategories(true);
                              }}
                            >
                              + Add new Category
                            </Button>
                          )}
                        </Box>
                        <Autocomplete
                          multiple
                          options={categoriesData}
                          value={formik.tags}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(event: any, newValue: any) => {
                            console.log(newValue, "newValuenewValue");
                            setFormik({ ...formik, tags: newValue })
                          }}
                          renderInput={(params: any) => <TextField {...params} placeholder={formik.tags.length === 0 ? "Select Category" : ""} />}
                          sx={{
                            '& .MuiInputBase-root': {
                              display: 'flex',
                              alignItems: 'center',
                              background: '#FFFFFF',
                              borderRadius: '8px',
                              padding: '6px 9px',
                              minHeight: "48px",
                              maxHeight: "48px",
                              fontSize: "12px",
                              '&.Mui-focused': {
                                borderColor: "#015241",
                                boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                              }
                            },
                          }}
                        />
                        {formik.tags.length <= 0 && submitted && (
                          <ErrorMessage>{errorsMsgText.tags}</ErrorMessage>
                        )}
                      </Box>
                    </Box>
                  </Box>

                </Box>

                <Box className="paydata_item_wrapper d-flex flex-column">
                  <Box className="paydata_item d-flex justify-content-between">
                    <Typography className='paydata_title'>Teams</Typography>
                    <Box className="d-flex gap-3 " style={{ cursor: "pointer" }}>
                      <Typography className='info' onClick={() => handleTeamOpen()}>
                        {formik.teams.length === 0 ? "Select team" : formik.teams.map((team: any, index: number) => (
                          <Typography key={index} style={{ color: "#025041", fontFamily: "Poppins", textAlign: "right" }}>
                            {team.name}
                          </Typography>
                        ))}

                      </Typography>
                      <ArrowForwardIosIcon className='arrowicon'
                        onClick={() => handleTeamOpen()}
                        style={{
                          transform: `rotate(${openStates.isTeamOpen ? 90 : 0}deg)`,
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>
                  </Box>


                  <Box className="vat_box" style={{
                    display: 'grid',
                    gridTemplateRows: openStates.isTeamOpen ? '1fr' : '0fr',
                    transition: 'grid-template-rows 0.3s ease',
                    overflowY: 'hidden'
                  }}>
                    <Box style={{
                      minHeight: 0,
                      opacity: openStates.isTeamOpen ? 1 : 0,
                      visibility: openStates.isTeamOpen ? 'visible' : 'hidden',
                      transition: 'opacity 0.2s ease, visibility 0.3s ease',
                    }}>
                      <Box className="mt-4">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className='label mb-2'>Select Team</Typography>
                          {newTeamModal == true ? (
                            <Button style={{ fontSize: "13px", textTransform: "capitalize" }} className="complete-profile__ADDBTNHHA">
                              + Add new team
                            </Button>
                          ) : (
                            <Button
                              style={{ fontSize: "13px", textTransform: "capitalize" }}
                              className="complete-profile__ADDBTNHHA"
                              onClick={() => {
                                // setNewTeam(!newTeam);
                                setnewTeamModal(true);
                              }}
                            >
                              + Add new team
                            </Button>
                          )}
                        </Box>
                        <Autocomplete
                          multiple
                          options={teamData}
                          value={formik.teams}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(event: any, newValue: any) => {
                            setFormik({ ...formik, teams: newValue })
                          }}
                          renderInput={(params: any) => <TextField
                            sx={{
                              '& .MuiInputBase-input': {
                                '&::placeholder': {
                                  color: "#6D6B6B"
                                }
                              }
                            }}
                            {...params} placeholder={formik.teams.length === 0 ? "Select Team" : ""} />}
                          sx={{
                            '& .MuiInputBase-root': {
                              display: 'flex',
                              alignItems: 'center',
                              background: '#FFFFFF',
                              borderRadius: '8px',
                              padding: '6px 9px',
                              minHeight: "48px",
                              maxHeight: "48px",
                              fontSize: "12px",
                              '&.Mui-focused': {
                                borderColor: "#015241",
                                boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                              }
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="paydata_item_wrapper d-flex flex-column">
                  <Box className="paydata_item d-flex justify-content-between align-items-center">
                    <Typography className='paydata_title'>VAT</Typography>
                    <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                      <Typography className='info' onClick={() => handleVatOpen()}>{formik.vat ? formik.vat == "Exempt" ? formik.vat : `${formik.vat}%` : "Select VAT"}</Typography>
                      <ArrowForwardIosIcon className='arrowicon'
                        onClick={() => handleVatOpen()}
                        style={{
                          transform: `rotate(${openStates.isVatOpen ? 90 : 0}deg)`,
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="vat_box" style={{
                    display: 'grid',
                    gridTemplateRows: openStates.isVatOpen ? '1fr' : '0fr',
                    transition: 'grid-template-rows 0.3s ease',
                  }}>
                    <Box style={{
                      minHeight: 0,
                      opacity: openStates.isVatOpen ? 1 : 0,
                      visibility: openStates.isVatOpen ? 'visible' : 'hidden',
                      transition: 'opacity 0.2s ease, visibility 0.3s ease'
                    }}>
                      <Box className="mt-4">
                        <Typography className='label mb-2'>Select VAT</Typography>
                        <TheSelectBox
                          value={formik.vat}
                          options={vatList}
                          defaultOption="Select VAT"
                          onChange={(e: any) => {
                            setFormik({ ...formik, vat: e.target.value })
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {DuplicatetarModel == true ?
                  <TransactionExistCom onSubmit={onSubmit} onCloseTransactionExist={onCloseTransactionExist} TransactionExistMessage={DuplicatetarMsg} TransactionExist={DuplicatetarModel} /> : ""}
                <Box className="paydata_item_wrapper d-flex flex-column">
                  <Box className="paydata_item d-flex justify-content-between align-items-center">
                    <Typography className='paydata_title'>Description</Typography>
                    <Box className="d-flex gap-3 align-items-center "
                      onClick={() => {
                        handleToggle('isDescriptionOpen');
                        setTimeout(handleFocus, 100);
                        // inputRef.current.focus()
                      }}
                      style={{ cursor: "pointer" }}>
                      <Tooltip title={description ? description : ""}>
                        <Typography className='info'>
                          {formik.description
                            ? formik.description.length > 20
                              ? `${formik.description.slice(0, 20)}...`
                              : formik.description
                            : "Enter description"}
                        </Typography>
                      </Tooltip>
                      <ArrowForwardIosIcon className='arrowicon'
                        style={{
                          transform: `rotate(${openStates.isDescriptionOpen ? 90 : 0}deg)`,
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="vat_box" style={{
                    display: 'grid',
                    gridTemplateRows: openStates.isDescriptionOpen ? '1fr' : '0fr',
                    transition: 'grid-template-rows 0.3s ease',

                  }}>
                    <Box style={{
                      minHeight: 0,
                      opacity: openStates.isDescriptionOpen ? 1 : 0,
                      visibility: openStates.isDescriptionOpen ? 'visible' : 'hidden',
                      transition: 'opacity 0.2s ease, visibility 0.3s ease'
                    }}>
                      <Box className="mt-4">
                        <Typography className='label'>Enter description</Typography>
                        <CustomInput
                          autofocus={inputRef}
                          value={formik.description}
                          onChange={(e: any) => setFormik({ ...formik, description: e.target.value })}
                          placeholder="Enter description"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {formik.currency === "USD" && parseFloat(formik.amount) >= 10000 && (
                  <Box className="paydata_item_wrapper d-flex flex-column">
                    <Box className="paydata_item d-flex justify-content-between align-items-center">
                      <Typography className='paydata_title'>Supporting document</Typography>
                      <Box className="d-flex gap-3 align-items-center " onClick={() => {
                        handleToggle('isDocumentOpen');
                      }} style={{ cursor: "pointer" }}>
                        <Tooltip title={invoicefile ? invoicefile : ""}>
                          <Typography className='info' onClick={() => handleToggle('isDocumentOpen')}>{invoicefile ? invoicefile.slice(0, 20) + (invoicefile.length > 20 ? "..." : "") : "Select Document"}</Typography>
                        </Tooltip>
                        <ArrowForwardIosIcon className='arrowicon'
                          style={{
                            transform: `rotate(${openStates.isDocumentOpen ? 90 : 0}deg)`,
                            transition: "transform 0.3s ease",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className="vat_box" style={{
                      display: 'grid',
                      gridTemplateRows: openStates.isDocumentOpen ? '1fr' : '0fr',
                      transition: 'grid-template-rows 0.3s ease',
                    }}>
                      <Box style={{
                        minHeight: 0,
                        opacity: openStates.isDocumentOpen ? 1 : 0,
                        visibility: openStates.isDocumentOpen ? 'visible' : 'hidden',
                        transition: 'opacity 0.2s ease, visibility 0.3s ease'
                      }}>
                        <Box style={{
                          minHeight: 0,
                          overflowY: 'auto',
                          opacity: openStates.isMethodOpen ? 1 : 0,
                          visibility: openStates.isMethodOpen ? 'visible' : 'hidden',
                          transition: 'opacity 0.2s ease, visibility 0.3s ease'
                        }}>
                          {/* <Box>
                            <AddPaymentMethod
                              UpdetPayee={UpdetPayee}
                              setIsMethodOpen={setOpenStates}
                              setFormik={setFormik}
                              ChackBlence={ChackBlence}
                              formik={formik}
                              SelectCurrency={SelectCurrency}
                              enterAmount={formik.amount}
                              setIsAddPayeeComponent={setIsAddPayeeComponent}
                              stepupdatefun={stepupdatefun}
                              // paymentData={paymentData}
                              // setPaymentData={setPaymentData}
                              selectedPayee={selectpayees}
                              payee={selectpayees}
                              onClose={() => setIsMethodOpen(!isMethodOpen)}
                            />
                          </Box> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {newTeamModal ? (
                  <Modal
                    show={newTeamModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                  ><Modal.Body>
                      <Box className="box_dialog_main">
                        <Typography className={"Create_Team_heding"}>{"Create Team"}</Typography>
                        <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                          <label>Name of team</label>
                          <InputGroup className="doc-input-wrapper">
                            <Form.Control
                              className={'doc-form-input test valid'}
                              placeholder="Name of team"
                              value={field_data}
                              name='name'
                              type="text"
                              onChange={(e: any) => {
                                setField_data(e.target.value)
                              }}
                            />
                          </InputGroup>
                          {!field_data && validesan == true ? <p className="error-message">Please enter the team name</p> : cereteTeam_validet == "2" ? <p className="error-message">The name has already been taken.</p> : ""}
                        </div>

                        <Box className="box_btn_cencal" sx={{ justifyContent: "space-between", display: "flex" }}>
                          <Button
                            className="cerete_user"
                            onClick={() => { onCloseTeam() }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={{ backgroundColor: isHovered ? '#f0f0f0' : 'transparent', borderColor: isHovered ? '#025041' : '' }}>
                            Cancel
                          </Button>
                          <Button className={"savebtn"}
                            onClick={() => {
                              CreateTeam()
                            }}>Create Team</Button>


                        </Box>
                      </Box></Modal.Body></Modal >

                ) : (
                  " "
                )}
                <ModelCategories
                  show={OpenModelCategories}
                  setName={setName}
                  Name={Name}
                  errorMsg={errorMsg}
                  validesan={Submit}
                  Close={ModelCategoriesClose}
                  CereteCategories={CreateCategories}
                />
                {errorsmsgapi && submitted ? <Box style={{ marginBottom: "15px" }}><ErrorMessage>{errorsmsgapi}</ErrorMessage></Box> : !!errorsMsg && submitted ? <Box style={{ marginBottom: "15px" }}><ErrorMessage>{errorsMsg}</ErrorMessage></Box> : ""}

              </Box>

              <Box className="d-flex justify-content-between align-items-center" style={{ fontFamily: "Poppins" }}>
                <LightBtn
                  onClick={() => {
                    // stepupdatefun(2)
                    if (!formik.tags || formik.tags.length === 0) handleToggle('isCategoryOpen');
                    if (formik.type === '' && !!formik.amount) {
                      handleToggle('isMethodOpen');
                    }
                    if (formik.currency === "USD" && parseFloat(formik.amount) >= 10000 && !formik.invoice_record) handleToggle('isDocumentOpen');
                    if (
                      !errorsMsg &&
                      !errorsmsgapi &&
                      !!formik.counterpart &&
                      !!formik.currency &&
                      !!formik.amount &&
                      formik.amount != 0 &&
                      formik.tags.length > 0 &&
                      !!formik.type &&
                      !!formik.country_id
                    ) {
                      if (errorsmsgapi == "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
                        setDuplicateTransaction(true)
                      } else {
                        setIsStepsNavHidden(true)
                        setIsSchedulePayment(true)
                        setSubmitted(true)
                      }
                    } else {
                      if (errorsmsgapi == "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
                        setDuplicateTransaction(true)
                      } else {
                        setSubmitted(true)
                      }
                    }
                  }}
                  text="Schedule for later" style={{ fontFamily: "Poppins" }} />
                <DefaultBtn
                  type='button'
                  onClick={() => {
                    // stepupdatefun(2)
                    // setIsSchedulePayment(true)
                    if (!formik.tags || formik.tags.length === 0) handleToggle('isCategoryOpen');
                    if (!!formik.amount && formik.type === '') {
                      handleToggle('isMethodOpen');
                    }
                    if (formik.currency === "USD" && parseFloat(formik.amount) >= 10000 && !formik.invoice_record) handleToggle('isDocumentOpen');
                    if (
                      !errorsMsg &&
                      !errorsmsgapi &&
                      !!formik.counterpart &&
                      !!formik.currency &&
                      !!formik.amount &&
                      formik.amount != 0 &&
                      formik.tags.length > 0 &&
                      !!formik.type &&
                      !!formik.country_id
                    ) {
                      if (errorsmsgapi == "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
                        setDuplicateTransaction(true)
                      } else {
                        stepupdatefun(2)
                        setSubmitted(true)
                      }
                    } else {
                      if (errorsmsgapi == "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
                        setDuplicateTransaction(true)
                      } else {
                        setSubmitted(true)
                      }
                    }
                  }}
                  text="Pay now" />
              </Box>
            </Box>
          </Box>
        )
      }



    </>

  );
};

export default Step2;