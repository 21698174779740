import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./notifypayment.scss";
import { InputNumber, PhoneInputcom } from "../../../input";
import { ErrorMessage } from "../../../eroor";
import { ButtonSave, CreateButton, SaveButton } from "../../../Button";
import { payablesFlowactions } from "../../../../../_actions";
import { Button } from "react-bootstrap";
import { DefaultBtn } from "../../Buttons/Buttons";
import { CustomInput } from "../../Inputs/Inputs";


interface Props {
    onSave: any;
    counterpart: any;
    data: any;
    paymentDeatils: any;
    type: string;
}

export const NotifyPayment = (props: any) => {
    let navigate = useNavigate();
    const [phonedata, setPhonedata] = useState('');
    const [dataemail, setEmaildata] = useState('');
    const [moneetID, setMoneetID] = useState('');
    const [padid, setPadid] = useState('');
    const [option, setOption] = useState<any>({ id: '' });
    const [data_chack, setDATAchack] = useState(false);
    const [transloader, setTransactionLoader] = useState(false);
    const [detailsPayment, setDetailsPayment] = useState<any>();
    const [desplay, setDesplay] = useState(true);
    const [validData, setVailddata] = useState(false)
    const [emailvalidation, setEmailvalidation] = useState(false)
const[loader,setLoader]=useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        setOption(props.data);
        setDetailsPayment(props.paymentDeatils);
        if (!!props.counterpart && desplay) {
            const monite_counterpart_id = !!props.counterpart.monite_id
                ? props.counterpart.monite_id
                : props.counterpart.monite_counterpart_id;
            if (props.type === 'send money' && !!props.counterpart.notify_email) {
                setEmaildata(props.counterpart.notify_email);
            }

            setPadid(props.counterpart.id);
            setMoneetID(monite_counterpart_id);
            setDesplay(false);
        }
    }, [props, desplay]);
    const inviteToNiobi = (data: any) => {
        saveata();
    }
    const onSubmitted = () => {
        if (!!isValidEmail(dataemail) || !!phonedata) {
            props.setLoader(false)
            const body = {
                amount: option.amount,
                transaction_date: moment(option?.updated_at).format('DD-MMM-YYYY'),
                mobile: phonedata,
                email: dataemail,
                transaction_id: option?.ref,
                payee_id: moneetID,
                payment_method: option?.payment_channel,
                transaction_description: detailsPayment?.description,
                payable_id: option?.payable_id,
            };
            setLoader(true)
            dispatch(payablesFlowactions.PostPaymentfull("/invite-to-niobi", body, inviteToNiobi, setTransactionLoader,true) as any)

        } else {
            setLoader(false)
            setVailddata(true)
            setEmailvalidation(true)
        }
    };
    const saveCounterparts = (data: any) => {
        window.location.href = '/balance';
    }
    const saveata = () => {
        const body = {
            id: moneetID,
            notify_phone_number: phonedata,
            notify_email: dataemail,
        };
        setLoader(true)
        dispatch(payablesFlowactions.PostPaymentfull("/counterparts/update-counterpart", body, saveCounterparts, setTransactionLoader,false) as any)
    };

    // email validation
    function isValidEmail(email: any) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }

    const isButtonDisabled = transloader || (!dataemail && !phonedata) || (dataemail && !isValidEmail(dataemail)) || (phonedata && phonedata.length !== 12);

    return (
        <div className='makepayment-invite-niobi-balance'>
            <div className='makepayment-invite-niobi-balance__heading'>
                Notify Payee
            </div>
            {props.type === 'send money' ? "" :
                <Box className="addphoneno">
                    <label>Phone Number</label>
                    <PhoneInputcom
                        value={phonedata}
                        onChange={(value: any, country: any, e: any, formattedValue: any) => {
                            props.setLoader(false)
                            setPhonedata(value)
                            setVailddata(true)
                        }} />
                    {
                        validData == true ?
                            !!phonedata ?
                                (phonedata.length != 12 ?
                                    <Box style={{ marginTop: "6px" }}>
                                        <ErrorMessage>Please enter 9 digit phone number</ErrorMessage>
                                    </Box>
                                    : '')
                                : " "
                            : ""
                    }

                </Box>}

            <Box>
                <label className="email_label">Email</label>
                <CustomInput
                    value={dataemail}
                    placeholder="Enter email"
                    onChange={(e: any) => {
                        props.setLoader(false)
                        setEmaildata(e.target.value)
                        isValidEmail(e.target.value)

                    }} />
                {props.type === 'send money' && !isValidEmail(dataemail) && validData == true ? <ErrorMessage>Please enter a valid email address.</ErrorMessage> : ""}
                {props.type == 'send money' ? "" :
                    <Box style={{marginTop: "-3px"}}>
                        {props.type !== 'send money' && (!dataemail && !phonedata) && (
                            <ErrorMessage >Please fill in at least one of the following: valid email or phone number.</ErrorMessage>
                        )}

                        {props.type !== 'send money' && validData === true && !isValidEmail(dataemail) && dataemail && (
                            <ErrorMessage >Please enter a valid email address.</ErrorMessage>
                        )}
                    </Box>}

            </Box>

            <div className='notify_btn_wrapper d-flex justify-content-end'>
                <DefaultBtn
                    loader={loader}
                    style={{ width: "150px" }}
                    text="Notify Payee"
                    disabled={isButtonDisabled}
                    onClick={() => {
                        props.setLoader(true)
                        onSubmitted()
                    }}
                />
            </div>
        </div>
    );
};
