import React, { useCallback, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Button,
  InputGroup,
  Nav,
  Row,
  Tab,
  Tabs,
  Modal,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
//import DatePicker from "./Datepicker";
// import { PDFDocument } from '@pdf-lib/core';
import html2canvas from 'html2canvas';
import Multiselect from 'multiselect-react-dropdown';
import '../../Bill.scss';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../../../_helpers';
import { getBase64, getKESFormate } from '../../../../../../_helpers/common';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { useDispatch, useSelector } from 'react-redux';
import { PayablesRequest } from '../../../../../../interfaces/interfaces';
import * as Yup from 'yup';
import { payablesActions } from '../../../../../../_actions/payables.actions';
import { Select } from '@team-monite/ui-kit-react';
import { useNavigate } from 'react-router-dom';
import {
  Formik,
  Field,
  ErrorMessage,
  useField,
  useFormikContext,
} from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { selectAllPayablesListData } from '../../../../../../_reducers/payables.reducer';
import { settingActions } from '../../../../../../_actions/setting.actions';
import { settingSelector } from '../../../../../../_reducers/setting.reducer';
import moment from 'moment';
import amplitude from 'amplitude-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { orange } from '@mui/material/colors';
import styles from './Document.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Document,
  Page,
  Image,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import { createCanvas, loadImage } from 'canvas';
import { handleFileUpload } from '../../../../../utils';
import posthog from 'posthog-js';
import { NewCreate_Account_from } from '../../../../../auth/Create_Account/NewCreate_Account_from';
import { Box, Typography } from '@mui/material';
import { dashboardActions } from '../../../../../../_actions';
import { selectAllData } from '../../../../../../_reducers/dashboard.reducer';

const AddICon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
      fill="#025041"
    />
  </svg>
);

export const DocumentForm = (props: any) => {
  let navigate = useNavigate();

  const [value, onChange] = useState(new Date());
  const [options, setOptions] = useState([
    { name: 'Option 1️', id: 1 },
    { name: 'Option 2️', id: 2 },
  ]);
  const directorStatic = {
    tax: '',
    Unit_Price: '0',
    name: '',
    quantity: '0',
    description: '',
    total: 0,
  };
  const directorStatic_TWO = {
    tax: '',
    name: '',
    quantity: 0,
    description: '',
    total: 0,
  };
  const [submitted, setSubmitted] = useState(false);

  const [users, setUsers] = useState<any>();
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [payloadPDF, setPayloadPDF] = useState<any>('');
  const [invoicefile, setinvoicefile] = useState(
    'Upload supporting document for record'
  );
  const [loading, setLoading] = useState(false);

  const [isUsers, isSetUsers] = useState<any>(true);
  const [counterpartsList, setCounterpartsList] = useState<any>();
  const [counterpartsListRender, setCounterpartsListRender] = useState(false);
  const [render, setRender] = useState(true);
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [dataadd, setDataAdd] = useState(false);
  const [tagsList, setTagsList] = useState<any>([]);
  const [tagsListRender, setTagsListRender] = useState(true);
  const [newPayee, setNewPayee] = useState(false);
  const [selectedList, setSelectedList] = useState<any>([]);
  const dispatch = useDispatch();
  const [invoiceDate, setInvoiceDate] = useState<any>(new Date());
  const [dueDate, setDueDate] = useState<any>(new Date());
  const [btnType, setBtnType] = useState('submit');
  const [itemRender, setItemRender] = useState(true);
  const [item, setItem] = useState<any>();
  const [authoriseTransaction, setAuthoriseTransaction] = useState(false);
    const [currencyAndMethodData, setCurrencyAndMethodData] = useState([]);
  const [requestRender, setrequestRender] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [payeename, setpayeename] = useState('');
  const [userRender, setUserRender] = useState(true);
  const [userDetail, setUserDetail] = useState<any>();
  const [categoriesError, setCategoriesError] = useState<any>(false);
  const [payablesListRender, setPayablesListRender] = useState<any>(false);
  const [directors, setDirectors] = useState([directorStatic]);
  const [directors_two, setDirectors_two] = useState([directorStatic_TWO]);
  const [inputList, setInputList] = useState([{ firstName: '', lastName: '' }]);
  const [fileSize, setFileSize] = useState<any>('');
  const [vatall, setVatall] = useState([]);
  const [totalVat, setTotelVat] = useState(0);
  const [data_total, setDataTotal] = useState<any>(0);
  const [counterpartsListRender_two, setCounterpartsListRender_two] =
    useState(true);
  const [submit_button, setSubmit_button] = useState(false);
  //Teams data
  const [teams, setTeams] = useState([]);
  const [teamsRender, setTeamsRender] = useState(true);
  const [newTeam, setNewTeam] = useState(false);
  const [newTeamModal, setnewTeamModal] = useState(false);
  const [fild_data, setFild_data] = useState('');
  const [validesan, setValidesan] = useState(false)
  const [cereteTeam_validet, setCereteTeam_validet] = useState("")
  const [selectedTeamList, setSelectedTeamList] = useState<any>([]);
  const [isHovered, setIsHovered] = useState(false);
  const onClose_cerete = () => {
    setnewTeamModal(false);
    setFild_data('')
  };
  const ONsave = () => {
    setTimeout(() => {
      setTeamsRender(true)
    }, 4000)
    setCereteTeam_validet("1")
    setFild_data('');
    onClose_cerete()
  }
  const Vlidesa = () => {
    setCereteTeam_validet("2")
  }
  const CereteTeam = () => {
    setValidesan(true)
    if (!!fild_data) {
      var reqBody = {
        "name": fild_data
      }
      dispatch(settingActions.Team_cerete(reqBody, ONsave, Vlidesa) as any)
    }
  }


  const userD = getUser()?.data ?? null;
      const data = useSelector((state) => selectAllData(state));
  
  const dateformet = new Date();

  const [submittedData, setSubmittedData] = useState<any>({
    invoice_record: '',
    document_id: '',
    payment_date: '',
    amount: '0',
    supplier_id: '',
    submitted_by: '',
  });
  const formInitailData = {
    payment_date: '',
    due_date: '',
    // amount: "0",
    submitted_by: '',
    supplier_id: '',
    new_supplier_id: '',
    invoice_record: '',
    invoice_date: moment(dateformet).format('YYYY-MM-DD'),
    document_id: '',
    description: '',
    categories: '',
    teams: '',
    currency:''
  };

  const handleSubmit = (e: any) => {
    const { name, value }: any = e.target;

    setSubmittedData({
      ...submittedData,
      [name]: value,
      submitted_by: userDetail?.email,
    });

    // const request = {...submittedData,...invoiceDate,...dueDate}
  };
  const onHide = () => {
    props.props.setIsOpen(false);
  };
  useEffect(() => {
        dispatch(dashboardActions.getDashboardCurrencyData() as any);

    }, [dispatch]);

    useEffect(() => {
        if (data && data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
            setCurrencyAndMethodData(data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
        }
    }, [data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS]);
  const changeTohandle = (e: any) => {
    navigate('/Counterparts?popup=' + e.target.value, {
      replace: true,
      state: { popup: e.target.value },
    });
    // if(e.target.value == 0){
    //   setType(CounterpartType.ORGANIZATION)
    // }elsee.target.value
    //   setType(CounterpartType.INDIVIDUAL)
    // }
  };
  const balancelistData = useSelector((state) =>
    selectAllPayablesListData(state)
  );
  const userData = useSelector((state) => settingSelector(state));



  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  useEffect(() => {
    if (requestRender && balancelistData?.PAYABLES_REQUEST == true) {
      setLoading(true);
      setrequestRender(false);
      setresponseRender(true);
    }
  }, [balancelistData, requestRender]);


  React.useEffect(() => {
    if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
      setUserRender(false);
      setUserDetail(userData?.GETUSERDETAIL_SUCCESS);
    }
  }, [userData, userRender]);

  useEffect(() => {
    if (
      responseRender &&
      counterpartsListData?.SUBMIT_PAYABLES_REQUEST == false
    ) {
      setLoading(false);
      setresponseRender(false);
    }
  }, [counterpartsListData, responseRender]);
  useEffect(() => {
    if (
      counterpartsListRender_two &&
      counterpartsListData?.GETALLVAT_SUCCESS !== undefined
    ) {
      setCounterpartsListRender_two(false);
      setVatall(counterpartsListData?.GETALLVAT_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender_two]);
  useEffect(() => {
    if (payablesListRender && balancelistData?.PAYABLES_SUCCESS !== undefined) {

      setPayablesListRender(false);
      const bodyData = {
        payables: [balancelistData?.PAYABLES_SUCCESS.id],
      };
      dispatch(counterpartsActions.submitPaybles(bodyData) as any);
    }
  }, [balancelistData, payablesListRender]);
  const handleImage = async (e: any) => {
    setFileSize(e.target.files[0].size);
    setinvoicefile(e.target.files[0].name);
    const fileBase64 = await handleFileUpload(e);
    setinvoiceImage(fileBase64);
  };

  useEffect(() => {
    if (getUser && isUsers) {
      isSetUsers(false);
      const bodyReq = {
        id: getUser()?.data.user_id,
      };
      dispatch(settingActions.getUserDetail(bodyReq, "no") as any);
    }
  }, [isUsers, getUser]);

  useEffect(() => {
    const inVoiceObj = {
      invoice_date: invoiceDate.toISOString().substring(0, 10),
    };
    const dueDateObj = { due_date: dueDate.toISOString().substring(0, 10) };
    const imageObj = { invoice_record: invoiceImage };
    const req = [submittedData, inVoiceObj, dueDateObj, imageObj];
    const finalRequest = req.reduce(function (result: any, current) {
      return Object.assign(result, current);
    }, {});
    const tags = selectedList?.map((item: any) => {
      return item.id;
    });
    const teamsList = selectedTeamList?.map((item: any) => {
      return item.id;
    });

    const finalArrayRequest = { ...finalRequest, tags: tags, teams: teamsList };



    // if (props.props.isApiData){
    //   dispatch(payablesActions.create(finalArrayRequest) as any)
    //   props.props.setIsAPIData(false)
    //   props.props.setIsOpen(false)
    // }

    // if (props.props.isPayApiData) {
    //   dispatch(payablesActions.createPay(finalArrayRequest) as any)
    //   props.props.setIsPayAPIData(false)
    //   props.props.setIsOpen(false)
    // }
  }, [submittedData, invoiceDate, dueDate, selectedList, selectedTeamList, props, invoiceImage]);



  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(counterpartsActions.getAllvat() as any);
      dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any);
      dispatch(counterpartsActions.getAllTags() as any);
      dispatch(settingActions.getTeamDocuments() as any);

    }
  }, [render, isUserAuthenticated]);
  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsListRender(false);
      setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender]);

  useEffect(() => {
    if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
      let options: any = [];
      if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
        counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
          options.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      setTagsListRender(false);
      setTagsList(options);
    }
  }, [counterpartsListData, tagsListRender]);

  useEffect(() => {
    if (teamsRender && userData?.TEAMDOCUMENT_SUCCESS !== undefined) {
      let options: any = [];
      if (userData.TEAMDOCUMENT_SUCCESS.length > 0) {
        userData.TEAMDOCUMENT_SUCCESS
          .filter((item: any) => item.status === 'active')
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((item: any, ind: number) => {
            options.push({
              id: item.id,
              name: item.name,
            });
          });
      }
      setTeamsRender(false);
      setTeams(options);
    }
  }, [userData, teamsRender]);

  const onSelect = (selectedList: any) => {
    setSelectedList(selectedList);
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {

    };
  };
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const validationSchema = Yup.object().shape({
    supplier_id: Yup.string()
      // .min(2, "*Names must have at least 2 characters")
      // .max(100, "*Names can't be longer than 100 characters")
      .required("Please select the payee's name."),
    document_id: Yup.string().required('Please enter the invoice number.'),
    invoice_date: Yup.string().required('Please enter the invoice date.'),
    due_date: Yup.string().required('Please enter the due date.'),
    // amount: Yup.string().required("Please enter the amount."),
    currency: Yup.string()
    .required("Please select the Currency."),
    description: Yup.string().max(
      500,
      'Description should be maximum 500 characters'
    ),
    categories: Yup.string().required('Please select the category.'),
    invoice_record: Yup.mixed()
      .nullable()
      .notRequired()
      .test('file Size', 'File size too large, max file size is 1 Mb', () => {
        let valid = true;
        if (!!fileSize) {
          if (1024 * 1024 <= fileSize) {
            valid = false;
          }
        }
        return valid;
      }),
    // blog: Yup.string()
    // .url("*Must enter URL in http://www.example.com format")
    // .required("*URL required")
  });
  const handleRemoveClick = (index: any) => {

    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    /* for name */

    const listName = [...directors];
    listName.splice(index, 1);
    const listName_two = [...directors_two];
    listName_two.splice(index, 1);
    setDirectors_two(listName_two);
    setDirectors(listName);
  };

  const handleAddClick2 = () => {
    setInputList([...inputList, { firstName: '', lastName: '' }]);
    // setShareholdertwo([...shareholdertwo, shareHolderStatic])

    setDirectors([...directors, directorStatic]);
    setDirectors_two([...directors_two, directorStatic_TWO]);
    // setShareHoldersDocs([...ShareHolderdoc, directorDocStaticTwo])
    // setGroupTwoName([...groupTwoName, commonStatiTwo])
  };
  const [selectedValue, setSelectedValue] = useState(new Date());
  const [field, setField] = useState<any>([]);
  const [dataone, setdateone] = useState('');
  const [totelamount, setTotelAmount] = useState(0)
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the dollar sign at the end, with a space.
  });
  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };


  useEffect(() => {
    let datalist: any = 0;
    let vattotal: any = 0;
    let totalamount: any = 0;
    for (let index = 0; index < directors.length; index++) {
      const element = directors[index];
      var vat = element.tax == 'Exempt' ? 0 : element.tax;

      datalist += +element.total / (1 + (Number(vat) / 100));
      vattotal += +vat;
      totalamount += +element.total
    }
    setDataTotal((datalist).toString());
    setTotelAmount(totalamount)
    setTotelVat(vattotal);

  }, [directors]);
  const handleDownload = () => {
    const blob = base64ToBlob(invoiceImage);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.pdf';
    document.body.appendChild(link);

    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };
  var Amountgroos: any = totelamount - data_total
  return (
    <div className={styles.contenar_in_main}>
      <div>
        <h1 className={styles.heding_data_main_key}>
          Create One Off Bill{/* <InfoIcon /> */}
        </h1>
        {/* <div>
          <button onClick={handleDownload}>DOWNLOAD</button>
        </div> */}
        <Formik
          initialValues={formInitailData}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {

            // @ts-ignore
            const selected = counterpartsList.find((item: any) => {
              return item.monite_counterpart_id == values.supplier_id;
            });
            // const selectedTeam = selectedTeamList.find((item: any) => {
            //   return item.monite_counterpart_id == values.supplier_id;
            // });
            const payeeName =
              selected.counterpart_type == 'individual'
                ? selected.contact_first_name + ' ' + selected.contact_last_name
                : selected.name;


            const findAmount = directors.find((data: any) => data.Amount == '');

            const findItem_name = directors.find(
              (data: any) => data.name == ''
            );
            const findQuantity = directors.find(
              (data: any) => data.quantity == '' || data.quantity == '0'
            );
            const findUnit_Price = directors.find(
              (data: any) => data.Unit_Price == '' || data.Unit_Price == '0'
            );
            const findVat = directors.find((data: any) => data.tax == '');
            if (
              !findItem_name &&
              !findQuantity &&
              !findUnit_Price &&
              !findAmount
            ) {

              if (!!totelamount && totelamount >= 1000000 && !invoiceImage) {

                toast.error('Please attach a supporting document for the bill.');
              } else {



                const body = {
                  counterpart_id: values.supplier_id,
                  counterpart_name: payeeName,
                  submitted_by: userDetail?.email,
                  amount: (+totelamount * 100).toString(),
                  document_id: values.document_id,
                  due_date: values.due_date,
                  invoice_date: values.invoice_date,
                  invoice_record: invoiceImage,
                  currency:values.currency,
                  // description: values.description,
                  line_items: directors_two,
                  tags: selectedList?.map((item: any) => {
                    return item.id;
                  }),
                  teams: selectedTeamList?.map((item: any) => {
                    return item.id;
                  }),
                };
                setLoading(true);
                setrequestRender(true);
                setSubmitting(true);

                if (btnType == 'submit') {
                  const event = 'New Bill Created';
                  const event_two = 'Bill Submitted for Approval';
                  amplitude.getInstance().logEvent(event, event_two);
                  posthog.capture(event, eventProperties);

                  setPayablesListRender(true);

                  dispatch(payablesActions.createPay(body) as any);
                } else {
                  const event = 'New Bill Created ';
                  amplitude.getInstance().logEvent(event, eventProperties);
                  posthog.capture(event, eventProperties);

                  dispatch(payablesActions.create(body) as any);
                }

              }

            } else {
              setDataAdd(true);
            }
            // Simulate submitting to database, shows us values submitted, resets form
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   resetForm();
            //   setSubmitting(false);
            // }, 500);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>

              <Form onSubmit={handleSubmit}>
                <div className="displya_data_fastdiv displya_data_fastdiv_media ">
                  <div className="fast_button_and_input fast_button_and_input_media">
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="input-form-wrapper dot"
                    >
                      <label> Payee's name</label>
                      <div
                        className="maihagaygajnjaauh"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Form.Select
                          style={{ width: '70%' }}
                          name="supplier_id"
                          //onChange={handleSubmit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.supplier_id}
                          className={
                            touched.supplier_id && errors.supplier_id
                              ? ' doc-form-input error'
                              : 'doc-form-input'
                          }
                        >
                          <option selected value="">
                            Select payee
                          </option>
                          {counterpartsList?.map(
                            (arrayElement: any, index: number) => {
                              return (
                                <option
                                  value={arrayElement.monite_counterpart_id}
                                  key={index + 1}
                                >
                                  {arrayElement.counterpart_type == 'individual'
                                    ? arrayElement.contact_first_name +
                                    ' ' +
                                    arrayElement.contact_last_name
                                    : arrayElement.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Select>
                        <Button
                          style={{ padding: '14px 8px' }}
                          className="add_button_thisss"
                          onClick={() => {
                            navigate('/create-counterparts');
                            setNewPayee(!newPayee);
                          }}
                        >
                          + Add payee
                        </Button>
                      </div>
                      {touched.supplier_id && errors.supplier_id ? (
                        <div className="error-message">
                          {errors.supplier_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fast_button_and_input fast_button_and_input_media">
                    <label>Attach an invoice for record</label>
                    <ThemeProvider theme={theme}>
                      <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)">
                        <label className="complete-profile__upload  mb-0 ">
                          <div
                            id="afafafsdaFfsf"
                            className="complete-profile__upload__label"
                          // style={{ cursor: 'not-allowed' }}
                          >
                            <span className="complete-profile__upload__txt">
                              {invoicefile}
                            </span>
                            <UploadIcon />
                          </div>
                          <input
                            type="file"
                            name="invoice_record"
                            // disabled
                            onChange={handleImage}
                            className={
                              touched.invoice_record && errors.invoice_record
                                ? '  error'
                                : ''
                            }
                            //onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invoice_record}
                          />
                          {touched.invoice_record && errors.invoice_record ? (
                            <div className="error-message">
                              {errors.invoice_record}
                            </div>
                          ) : null}
                        </label>
                      </Tooltip>
                    </ThemeProvider>
                  </div>
                </div>

                <div></div>
                <div className="displya_data_fastdiv displya_data_fastdiv_media ">
                  <div className="fast_button_and_input fast_button_and_input_media">
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="dot"
                    >
                      <label className="Category_label">Category</label>
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          sx={{
                            '& .MuiInputBase-root': {
                              display: 'flex',
                              alignItems: 'center',
                              background: '#FFFFFF',
                              // border: '1px solid #DDDDDD',
                              outline: 'none',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '12px',
                              color: '#111111',
                              borderRadius: '8px',
                              padding: '6px 9px',
                            },
                          }}
                          multiple
                          limitTags={2}
                          id="multiple-limit-tags"
                          options={tagsList}
                          value={selectedList}
                          getOptionLabel={(option: any) => option.name}
                          //   defaultValue={selectedValue}
                          onChange={(e: any, newValue: any) => {


                            // setCategoriesError(false)
                            if (newValue.length != 0) {
                              values.categories = 'values';
                              errors.categories = '';
                              setCategoriesError(false);
                              setSelectedList(newValue);
                            } else {
                              values.categories = '';

                              setSelectedList(newValue);
                              setCategoriesError(true);
                            }

                            // setSelectedList(newValue)
                          }}
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                        // sx={{ width: '500px' }}
                        />
                      </ThemeProvider>
                      {/* <label>Categories</label> */}
                      {/* <ThemeProvider theme={theme}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={tagsList}
                        getOptionLabel={(option:any) => option.name}
                        // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params:any) => (
                          <TextField {...params} label="limitTags" placeholder="Favorites" />
                        )}
                        // sx={{ width: '500px' }}
                      />
                      </ThemeProvider> */}
                      {/* <Multiselect
                        options={tagsList} // Options to display in the dropdown
                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                        onSelect={(selectedList: any) => {
                          values.categories = "values";
                          errors.categories = undefined;
                          setCategoriesError(false);
                          setSelectedList(selectedList);
                        }} // Function will trigger on select event
                        onRemove={(selectedList: any) => {
                          if (selectedList.length == 0) {
                            values.categories = "";
                            setCategoriesError(true);
                          }
                        }}
                        displayValue="name" // Property name to display in the dropdown options
                        closeOnSelect={true}
                      /> */}
                      {errors.categories && submit_button == true ? (
                        <div className="error-message">
                          Please select the category
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fast_button_and_input fast_button_and_input_media">
                    <div
                      style={{ width: '100%' }}
                    >
                      <label> Link to Team</label>
                      <Button
                        // style={{ padding: '14px 8px', maxHeight: "48px" }}
                        style={{
                          padding: '14px 8px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          cursor: 'pointer',
                          boxSizing: 'border-box',
                          marginLeft: "35%"
                        }}

                        className="ADDBTNHHA"
                        onClick={() => {
                          setNewTeam(!newTeam);
                          setnewTeamModal(true);
                        }}
                      >
                        + Add New Team
                      </Button>
                      <div
                        className="maihagaygajnjaauh"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {/* <Form.Select
                          style={{ width: '60%' }}
                          name="team"
                          //onChange={handleSubmit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.team}
                          className={
                            touched.team && errors.team
                              ? ' doc-form-input error'
                              : 'doc-form-input'
                          }
                        >
                          <option selected value="">
                            Select team
                          </option>
                          {teams?.map(
                            (arrayElement: any, index: number) => {
                              return (
                                <option
                                  value={arrayElement.id}
                                  key={index + 1}
                                >
                                  {arrayElement.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Select> */}
                        <ThemeProvider theme={theme}>
                          <Autocomplete
                            sx={{
                              width: '100%',
                              // height: '50px',
                              '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                // border: '1px solid #DDDDDD',
                                outline: 'none',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '12px',
                                color: '#111111',
                                borderRadius: '8px',
                                padding: '6px 9px',
                              },
                            }}
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={teams}
                            value={selectedTeamList}
                            getOptionLabel={(option: any) => option.name}
                            //   defaultValue={selectedValue}
                            onChange={(e: any, newValue: any) => {


                              // setCategoriesError(false)
                              if (newValue.length != 0) {
                                values.categories = 'values';
                                errors.categories = '';
                                setSelectedTeamList(newValue);
                              } else {
                                values.categories = '';
                                setSelectedTeamList(newValue);
                              }
                            }}
                            filterOptions={(options, state) =>
                              options.filter(option =>
                                option.name.toLowerCase().includes(state.inputValue.toLowerCase())
                              )
                            }
                            renderInput={(params: any) => (
                              <TextField {...params} />
                            )}
                          />
                        </ThemeProvider>

                      </div>
                      {touched.teams && errors.teams ? (
                        <div className="error-message">
                          {errors.teams}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {newTeam ? (
                    <ThemeProvider theme={theme}>
                      <Modal
                        show={newTeamModal}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        centered
                      ><Modal.Body>
                          <Box className={styles.box_dialog_main}>
                            <Typography className={styles.Create_Team_heding}>{"Create Team"}</Typography>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                              <label>Name of team</label>
                              <InputGroup className="doc-input-wrapper">
                                <Form.Control
                                  className={

                                    'doc-form-input test valid'}
                                  placeholder="Name of team"
                                  value={fild_data}
                                  name='name'
                                  type="text"
                                  onChange={(e: any) => {
                                    setFild_data(e.target.value)
                                  }}
                                />
                              </InputGroup>
                              {!fild_data && validesan == true ? <p className="error-message">Please enter the team name</p> : cereteTeam_validet == "2" ? <p className="error-message">The name has already been taken.</p> : ""}
                            </div>

                            <Box className={styles.box_btn_cencal} sx={{ justifyContent: "space-between", display: "flex" }}>
                              <Button
                                className={styles.cerete_user}
                                onClick={() => { onClose_cerete() }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ backgroundColor: isHovered ? '#f0f0f0' : 'transparent', borderColor: isHovered ? '#025041' : '' }}>
                                Cancel
                              </Button>
                              <Button className={styles.savebtn} style={{ marginLeft: "0px" }}
                                onClick={() => {
                                  CereteTeam()
                                }}>Create Team</Button>


                            </Box>
                          </Box></Modal.Body></Modal >
                    </ThemeProvider>

                  ) : (
                    " "
                  )}
                </div>



                <div className="displya_data_fastdiv">
                  <div className="fast_button_and_input" style={{ marginRight: '1%' }}>
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="input-form-wrapper dot"
                    >
                      <label>Invoice number</label>
                      <InputGroup className="doc-input-wrapper ">
                        <Form.Control
                          className={
                            touched.document_id && errors.document_id
                              ? ' doc-form-input error'
                              : 'doc-form-input test valid'
                          }
                          //className="doc-form-input"
                          placeholder="Invoice number"
                          value={values.document_id}
                          //onChange={handleSubmit}
                          name="document_id"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        // value="24.06.22"
                        />
                      </InputGroup>
                      {touched.document_id && errors.document_id ? (
                        <div className="error-message" style={{ maxWidth: "100%", wordWrap: "normal", height: "2em" }}>
                          {errors.document_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fast_button_and_input" style={{ marginRight: '1%' }}>
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="input-form-wrapper dot "
                    >
                      <label>Invoice date</label>
                      <input
                        type="date"
                        className="doc-date doc-form-input form-control"
                        // onChange={setInvoiceDate}
                        value={values.invoice_date}
                        // defa="dd-MMM-yyyy"
                        // dateFormat="dd-MMM-yyyy"

                        name="invoice_date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.invoice_date && errors.invoice_date ? (
                        <div className="error-message" style={{ maxWidth: "100%", wordWrap: "normal", height: "2em" }}>
                          {errors.invoice_date}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="input-form-wrapper">
                      <label>Suggested payment date</label>
                      <InputGroup className="doc-input-wrapper input">
                        <Form.Control
                          className="doc-form-input"
                          placeholder="Suggested payment date"
                          value={submittedData?.payment_date}
                          onChange={handleSubmit}
                            name='payment_date'
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text className="input-mask" id="basic-addon2">
                          -2%
                        </InputGroup.Text>
                      </InputGroup>
                    </div> */}
                  <div className="fast_button_and_input">
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="input-form-wrapper dot "
                    >
                      <label>Due date</label>
                      <input
                        type="date"
                        className="doc-date doc-form-input form-control"
                        // onChange={setInvoiceDate}
                        value={values.due_date}
                        // format="yyyy-MM-dd"
                        name="due_date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.due_date && errors.due_date ? (
                        <div className="error-message" style={{ maxWidth: "100%", wordWrap: "normal", height: "2em" }}>{errors.due_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="input-form-wrapper">
          <label>Due date</label>
          <DatePicker
            className="doc-date"
            name='due_date'
            clearIcon={<></>}
            calendarIcon={calendarIcon()}
            onChange={setDueDate}
            value={dueDate}
            format="yyyy-MM-dd"
          />
        </div> */}

                <div style={{ paddingTop: '10px' }}>
                  {directors.map((example, i: any) => {
                    return (
                      <>
                        <div
                          className={styles.main_contenar_lineeee}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingTop: '15px',
                          }}
                        >
                          <label>Line item</label>

                          {i > 0 ? (
                            <Button
                              style={{ padding: '0px' }}
                              className="complete-profile__removebtn"
                              onClick={() => {
                                handleRemoveClick(i);
                              }}
                            >
                              Remove
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{ display: 'flex' }}
                          className={styles.manin_loop_collam}
                        >
                          <div
                            id={styles.main_input_datatatata}
                            style={{ margin: '0px 0px', width: '15%' }}
                            className="input-form-wrapper dot"
                          >
                            <label>Item name</label>
                            <InputGroup className="doc-input-wrapper">
                              <Form.Control
                                className={'doc-form-input test valid'}
                                placeholder="Item name"
                                value={example.name}
                                //onChange={handleSubmit}
                                name="Item_name"
                                type="text"
                                onChange={(e: any) => {

                                  const arrrr: any = [];
                                  const newarr: any = [];
                                  for (
                                    let index = 0;
                                    index < directors.length;
                                    index++
                                  ) {
                                    const element = directors[index];

                                    if (i == index) {
                                      element.name = e.target.value;
                                      arrrr.push(element);
                                    } else {
                                      arrrr.push(element);
                                    }
                                  }
                                  for (
                                    let index = 0;
                                    index < directors_two.length;
                                    index++
                                  ) {
                                    const element = directors_two[index];

                                    if (i == index) {
                                      element.name = e.target.value;
                                      newarr.push(element);
                                    } else {
                                      newarr.push(element);
                                    }
                                  }
                                  setDirectors_two(newarr);
                                  setDirectors(arrrr);
                                }}
                              />
                            </InputGroup>
                            {!example.name && dataadd == true ? (
                              <div className="error-message" style={{ maxWidth: "100%", wordWrap: "normal", height: "2em" }}>
                                Please enter an item name.
                              </div>
                            ) : null}
                          </div>
                          <div
                            id={styles.main_input_datatatata}
                            style={{
                              margin: '0px 0px',
                              width: '23.1%',
                              paddingLeft: '5px',
                            }}
                            className="input-form-wrapper dot"
                          >
                            <label>Unit price <span className={styles.smallText}> (tax inclusive) </span></label>
                            <InputGroup
                              className="doc-input-wrapper input"
                              style={{ padding: 0 }}
                            >
                              <MaskedInput
                                mask={numberMask}
                                style={{ height: '100%' }}
                                className="doc-form-input form-control"
                                placeholder="Unit price"
                                value={example.Unit_Price}
                                //onChange={handleSubmit}
                                name="Unit_Price"
                                onChange={(e: any) => {
                                  const kesValue = getKESFormate(
                                    e.target.value
                                  );

                                  const arrrr: any = [];
                                  const newarr: any = [];
                                  let amount_data: any = 0;
                                  for (
                                    let index = 0;
                                    index < directors.length;
                                    index++
                                  ) {
                                    const element = directors[index];
                                    // const newdata = directors;

                                    if (i == index) {
                                      element.Unit_Price =
                                        e.target.value.replaceAll(',', '');
                                      element.total =
                                        +e.target.value.replaceAll(',', '') *
                                        +example.quantity;
                                      // amount_data += +(+e.target.value.replaceAll(',', '') * +example.quantity).toString()
                                      arrrr.push(element);
                                    } else {
                                      arrrr.push(element);
                                    }
                                  }
                                  for (
                                    let index = 0;
                                    index < directors_two.length;
                                    index++
                                  ) {
                                    const element = directors_two[index];

                                    if (i == index) {
                                      element.total =
                                        +e.target.value.replaceAll(',', '') *
                                        100;
                                      newarr.push(element);
                                    } else {
                                      newarr.push(element);
                                    }
                                  }
                                  setDirectors_two(newarr);
                                  // setDataTotal(amount_data)
                                  // setDirectors_two(arrrr)
                                  setDirectors(arrrr);
                                }}
                                aria-describedby="basic-addon3"
                              />
                              {/* <InputGroup.Text
                                className="input-mask"
                                id="basic-addon3"
                              >
                                KES
                              </InputGroup.Text> */}
                            </InputGroup>
                            {/* {!example.Unit_Price && dataadd == true ? (
                              <div className="error-message">
                               Please enter the unit price
                              </div>
                            ) : null} */}
                            {(!example.Unit_Price && dataadd == true) || (example.Unit_Price == '0' && dataadd) ? (
                              <div className="error-message" style={{ maxWidth: "100%", wordWrap: "normal", height: "3em" }}>
                                {example.Unit_Price == '0' ? "Please enter a non-zero unit price" : "Please enter the unit price"}
                              </div>
                            ) : null}
                          </div>
                          <div
                            id={styles.main_input_datatatata}
                            style={{
                              width: '11%',
                              margin: '0px 0px',
                              paddingLeft: '5px',
                            }}
                            className="input-form-wrapper"
                          >
                            <label>VAT %</label>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Form.Select
                                name="Vat"
                                onChange={(e: any) => {
                                  const arrrr: any = [];
                                  const newarr: any = [];


                                  for (
                                    let index = 0;
                                    index < directors.length;
                                    index++
                                  ) {
                                    const element = directors[index];
                                    if (i == index) {
                                      element.tax = e.target.value;

                                      arrrr.push(element);
                                    } else {
                                      arrrr.push(element);
                                    }
                                    // const newdata = directors;

                                  }
                                  for (
                                    let index = 0;
                                    index < directors_two.length;
                                    index++
                                  ) {
                                    const element = directors_two[index];

                                    if (i == index) {
                                      element.tax = e.target.value;
                                      newarr.push(element);
                                    } else {
                                      newarr.push(element);
                                    }
                                  }
                                  setDirectors_two(newarr);
                                  setDirectors(arrrr);
                                }}
                                value={example.tax}
                                className={'doc-form-input'}
                              >
                                <option selected value="">
                                  VAT %
                                </option>
                                {vatall?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option
                                        value={arrayElement.vat}
                                        key={index + 1}
                                      >
                                        {arrayElement.vat}{' '}
                                        {arrayElement.vat == 'Exempt'
                                          ? ''
                                          : '%'}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                            {/* {!example.tax && dataadd == true ? (
                              <div className="error-message">VAT % in the required field</div>
                            ) : null} */}
                          </div>
                          <div
                            id={styles.main_input_datatatata}
                            style={{
                              margin: '0px 0px',
                              width: '12%',
                              paddingLeft: '5px',
                            }}
                            className="input-form-wrapper dot"
                          >
                            <label>Quantity </label>
                            <InputGroup className="doc-input-wrapper">
                              <Form.Control
                                className={'doc-form-input test valid'}
                                placeholder="Quantity"
                                value={example.quantity}
                                type="number"
                                onChange={(e: any) => {
                                  const arrrr: any = [];
                                  const newarr: any = [];
                                  let amount_data: any = 0;

                                  for (
                                    let index = 0;
                                    index < directors.length;
                                    index++
                                  ) {
                                    const element = directors[index];

                                    if (i == index) {
                                      element.quantity = e.target.value;
                                      element.total = +(
                                        +example.Unit_Price * +e.target.value
                                      );


                                      // amount_data += +(+example.Unit_Price * +e.target.value).toString()
                                      // element.amount_total=amount_data
                                      arrrr.push(element);
                                    } else {
                                      arrrr.push(element);
                                    }
                                  }
                                  for (
                                    let index = 0;
                                    index < directors_two.length;
                                    index++
                                  ) {
                                    const element = directors_two[index];

                                    if (i == index) {
                                      element.quantity = +e.target.value;
                                      element.total =
                                        +(+example.Unit_Price) * 100;
                                      newarr.push(element);
                                    } else {
                                      newarr.push(element);
                                    }
                                  }
                                  setDirectors_two(newarr);
                                  // setDataTotal(amount_data)
                                  setDirectors(arrrr);
                                }}
                                onBlur={handleBlur}
                              />
                            </InputGroup>
                            {(!example.quantity && dataadd == true) || (example.quantity == '0' && dataadd) ? (
                              <div className="error-message">
                                {example.quantity == '0' ? "Please enter a non-zero quantity" : "Please enter the quantity"}
                              </div>
                            ) : null}
                          </div>
                          <div
                            id={styles.main_input_datatatata}
                            style={{
                              margin: '0px 0px',
                              width: '25%',
                              paddingLeft: '5px',
                            }}
                            className="input-form-wrapper"
                          >
                            <label>Description</label>
                            <InputGroup className="doc-input-wrapper">
                              <Form.Control
                                className={'doc-form-input test valid'}
                                placeholder="Description"
                                value={example.description}
                                type="text"
                                onChange={(e: any) => {
                                  const arrrr: any = [];
                                  const newarr: any = [];

                                  for (
                                    let index = 0;
                                    index < directors.length;
                                    index++
                                  ) {
                                    const element = directors[index];
                                    if (i == index) {
                                      element.description = e.target.value;
                                      arrrr.push(element);
                                    } else {
                                      arrrr.push(element);
                                    }
                                  }
                                  for (
                                    let index = 0;
                                    index < directors_two.length;
                                    index++
                                  ) {
                                    const element = directors_two[index];

                                    if (i == index) {
                                      element.description = e.target.value;
                                      newarr.push(element);
                                    } else {
                                      newarr.push(element);
                                    }
                                  }
                                  setDirectors_two(newarr);

                                  setDirectors(arrrr);
                                }}
                                onBlur={handleBlur}
                              />
                            </InputGroup>
                            {/* {!example.description && dataadd == true ? (
                                                            <div className="error-message">Description in the required field</div>
                                                        ) : null} */}
                          </div>
                          <div
                            id={styles.main_input_datatatata}
                            style={{
                              margin: '0px 0px',
                              width: '13%',
                              paddingLeft: '5px',
                            }}
                            className="input-form-wrapper dot"
                          >
                            <label>Amount</label>
                            <InputGroup
                              className="doc-input-wrapper input"
                              style={{ padding: 0 }}
                            >
                              <MaskedInput
                                mask={numberMask}
                                style={{ height: '100%' }}
                                className="doc-form-input form-control"
                                disabled
                                placeholder="Amount"
                                value={+example.Unit_Price * +example.quantity}
                                onChange={(e: any) => {
                                  // const arrrr: any = []
                                  // for (let index = 0; index < directors.length; index++) {
                                  //     const element = directors[index];
                                  //     if (i == index) {
                                  //         element.total = (+example.Unit_Price) * (+example.quantity)
                                  //         arrrr.push(element)
                                  //     } else {
                                  //         arrrr.push(element)
                                  //     }
                                  // }
                                  // setDirectors_two(arrrr)
                                  // setDirectors(arrrr)
                                }}
                                aria-describedby="basic-addon3"
                              />
                              {/* <InputGroup.Text
                                className="input-mask"
                                id="basic-addon3"
                              >
                                KES
                              </InputGroup.Text> */}
                            </InputGroup>
                            {!example.total && dataadd == true ? (
                              <div className="error-message">
                                Please enter the unit price and quantity
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {directors.length - 1 === i && (
                          <Button
                            className="complete-profile__ADDBTNHHA"
                            onClick={handleAddClick2}
                          >
                            + Add line item
                          </Button>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className={styles.displya_data_fastdiv}>
                  <div className={styles.fast_button_and_input}>
                    <div className="input-form-wrapper dot" style={{ padding: 0 }}>
                      <label>Currency</label>

                      <Form.Select
                        style={{ fontSize: '11px' }}
                        name="currency"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currency}
                        className={'doc-form-input'}
                      >
                        <option selected value="">Currency</option>
                        {currencyAndMethodData?.map((arrayElement: any, index: number) => {
                          return (
                            <option value={arrayElement.currency} key={index + 1}>{arrayElement.currency}</option>
                          )
                        })}
                      </Form.Select>
                      {touched.currency && errors.currency ? (
                        <div className="error-message">{errors.currency}</div>
                      ) : null}
                    </div>



                  </div>

                  <div
                    className={styles.fast_button_and_input}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      className="input-form-wrapper"
                      style={{ width: '49%' }}
                    >
                      <label>VAT amount</label>
                      {/* <InputGroup className="doc-input-wrapper input"> */}
                      <InputGroup className="doc-input-wrapper input">
                        <div className='AmountBox'>
                          {Number(
                            parseFloat(Amountgroos).toFixed(2)
                          ).toLocaleString('en', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                        {/* <InputGroup.Text
                          className="input-mask"
                          id="basic-addon3"
                        >
                          KES
                        </InputGroup.Text> */}
                      </InputGroup>
                    </div>
                    <div
                      className="input-form-wrapper"
                      style={{ width: '49%' }}
                    >
                      <label>Gross amount</label>
                      <InputGroup className="doc-input-wrapper input">
                        <div className='AmountBox'>
                          {Number(
                            parseFloat(data_total).toFixed(2)
                          ).toLocaleString('en', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                        {/* <MaskedInput
                          mask={numberMask}
                          style={{ height: '100%' }}
                          className="doc-form-input form-control"
                          disabled
                          placeholder="Amount"
                          value={data_total}
                          //   {(totelamount - data_total).toLocaleString(
                          //   'en',
                          //   { minimumFractionDigits: 2 }
                          // )}
                          aria-describedby="basic-addon3"
                        /> */}

                        {/* <InputGroup.Text
                          className="input-mask"
                          id="basic-addon3"
                        >
                          KES
                        </InputGroup.Text> */}
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className={styles.displya_data_fastdiv}>
                  <div className={styles.fast_button_and_input}>
                    <div
                      style={{ width: '100%', margin: '10px 0px' }}
                      className="input-form-wrapper"
                    >
                      <label>Submitted by</label>
                      <InputGroup style={{ padding: "5px 12px" }} className="doc-input-wrapper input">
                        {userDetail?.name}

                      </InputGroup>
                    </div>
                  </div>
                  <div className={styles.fast_button_and_input} style={{ display: "flex", justifyContent: "end" }}>
                    <div
                      className="input-form-wrapper dot"
                      style={{ width: '100%', margin: '10px 0px', padding: 0 }}
                    >
                      <label>Amount</label>
                      <InputGroup
                        className="doc-input-wrapper input"
                        style={{ padding: 0 }}
                      >
                        <MaskedInput
                          mask={numberMask}
                          style={{ height: '100%' }}
                          className="doc-form-input form-control"
                          placeholder="Total"
                          disabled
                          value={totelamount}
                          //onChange={handleSubmit}
                          name="amount"
                          onChange={(e) => {
                            const kesValue = getKESFormate(e.target.value);
                            const obj = e;


                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          // value="5593.00"
                          aria-describedby="basic-addon3"
                        />
                        {/* <InputGroup.Text
                          className="input-mask"
                          id="basic-addon3"
                        >
                          KES
                        </InputGroup.Text> */}
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div
                  style={{ justifyContent: 'space-between' }}
                  className={styles.button_class}
                >
                  <Button
                    className="payDetails-modal__btn-row__white"
                    variant="custom"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <div
                    style={{ marginTop: '0', paddingTop: '0px' }}
                    className="payDetails-modal__btn-row_two"
                  >
                    <Button
                      className="payDetails-modal__btn-row__white"
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        setBtnType('save');
                        setDataAdd(true);
                        setSubmit_button(true);
                      }}
                    >
                      {loading == true && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Save
                    </Button>
                    {!!userD &&
                      !!userD.role_name &&
                      userD.role_name == 'Viewer' ? (
                      <Button
                        style={{ cursor: 'not-allowed' }}
                        className="payDetails-modal__btn-row__green "
                        variant="primary"
                        type="submit"
                        onClick={() => {
                          setSubmit_button(true);
                        }}
                      >
                        Submit for Approval
                      </Button>
                    ) : (
                      <Button
                        className="payDetails-modal__btn-row__green "
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        onClick={() => {
                          setBtnType('submit');
                          setDataAdd(true);
                          setSubmit_button(true);
                        }}
                      >
                        {loading == true && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Submit for Approval
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
    // <div></div>
  );
};

const onRemove = (selectedList: any, removedItem: any) => { };

const InfoIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6781 18.3332C15.2804 18.3332 19.0114 14.6022 19.0114 9.99984C19.0114 5.39746 15.2804 1.6665 10.6781 1.6665C6.07569 1.6665 2.34473 5.39746 2.34473 9.99984C2.34473 14.6022 6.07569 18.3332 10.6781 18.3332Z"
      stroke="#B8B8B8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.17773 7.50001C8.17773 4.58332 12.7611 4.58334 12.7611 7.50001C12.7611 9.58334 10.6777 9.16659 10.6777 11.6666"
      stroke="#B8B8B8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6777 15.0083L10.6861 14.999"
      stroke="#B8B8B8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UploadIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
      stroke="#B8B8B8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const calendarIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1V5M14 1V5M1 9H19M3 19H17C18.1046 19 19 18.1046 19 17V5C19 3.89543 18.1046 3 17 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19Z"
      stroke="#B8B8B8"
      stroke-width="2"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CounterpartMock = [
  {
    id: 17,
    name: null,
    user_id: '3c24a9e6-ca18-4b1c-9f84-ce90f19b8f4b',
    entity_id: 25,
    monite_entity_id: '37164371-d315-485e-92d0-124328335458',
    monite_counterpart_id: '4326462b-65d6-4802-909c-dffa3fb4f326',
    counterpart_type: 'individual',
    contact_first_name: 'Syed Hassan',
    contact_last_name: 'Gilani',
    contact_email: 'mesyedhassan@gmail.com',
    contact_phone: '',
    contact_country: null,
    contact_city: null,
    contact_postal_code: null,
    contact_state: null,
    contact_line1: null,
    is_default: 1,
    is_customer: 0,
    is_vendor: 1,
    kra_pin: null,
    country: null,
    city: null,
    postal_code: null,
    state: null,
    line1: null,
    bank_name: null,
    bank_branch: null,
    account_number: '80000001',
    account_name: null,
    pay_money: null,
    till_number: '690745',
    paybill_number: '810591',
    niobi_balance_account_id: null,
    phone_number: '254721548378',
    created_at: '2023-02-20T14:47:08.000000Z',
    updated_at: '2023-03-14T04:09:51.000000Z',
  },
  {
    id: 25,
    name: "Hassan's Company",
    user_id: '3c24a9e6-ca18-4b1c-9f84-ce90f19b8f4b',
    entity_id: 25,
    monite_entity_id: '37164371-d315-485e-92d0-124328335458',
    monite_counterpart_id: '6085c91d-49b7-4fc8-88de-f4158466e117',
    counterpart_type: 'organization',
    contact_first_name: null,
    contact_last_name: null,
    contact_email: 'mesyedhassan@gmail.com',
    contact_phone: '',
    contact_country: null,
    contact_city: null,
    contact_postal_code: null,
    contact_state: null,
    contact_line1: null,
    is_default: 1,
    is_customer: 0,
    is_vendor: 1,
    kra_pin: null,
    country: null,
    city: null,
    postal_code: null,
    state: null,
    line1: null,
    bank_name: null,
    bank_branch: null,
    account_number: '23232323',
    account_name: null,
    pay_money: null,
    till_number: '0000000',
    paybill_number: '810592',
    niobi_balance_account_id: null,
    phone_number: '0700000000000',
    created_at: '2023-02-21T05:48:46.000000Z',
    updated_at: '2023-03-13T11:15:39.000000Z',
  },
  {
    id: 26,
    name: "Hassan's Company",
    user_id: '3c24a9e6-ca18-4b1c-9f84-ce90f19b8f4b',
    entity_id: 25,
    monite_entity_id: '37164371-d315-485e-92d0-124328335458',
    monite_counterpart_id: 'ef695155-8ae0-456a-9861-72a9991c4cbe',
    counterpart_type: 'organization',
    contact_first_name: null,
    contact_last_name: null,
    contact_email: 'mesyedhassan@gmail.com',
    contact_phone: '',
    contact_country: null,
    contact_city: null,
    contact_postal_code: null,
    contact_state: null,
    contact_line1: null,
    is_default: 1,
    is_customer: 0,
    is_vendor: 1,
    kra_pin: null,
    country: null,
    city: null,
    postal_code: null,
    state: null,
    line1: null,
    bank_name: null,
    bank_branch: null,
    account_number: null,
    account_name: null,
    pay_money: null,
    till_number: 'wqqwqqw',
    paybill_number: null,
    niobi_balance_account_id: null,
    phone_number: null,
    created_at: '2023-02-21T05:48:46.000000Z',
    updated_at: '2023-03-13T13:41:04.000000Z',
  },
  {
    id: 42,
    name: null,
    user_id: '3c24a9e6-ca18-4b1c-9f84-ce90f19b8f4b',
    entity_id: 25,
    monite_entity_id: '37164371-d315-485e-92d0-124328335458',
    monite_counterpart_id: '538111ff-bb0a-4db2-81fb-6cac3058b91f',
    counterpart_type: 'individual',
    contact_first_name: 'Test',
    contact_last_name: 'Payee',
    contact_email: 'mesyedhassan+Test060323@gmail.com',
    contact_phone: '',
    contact_country: null,
    contact_city: null,
    contact_postal_code: null,
    contact_state: null,
    contact_line1: null,
    is_default: 1,
    is_customer: 0,
    is_vendor: 1,
    kra_pin: null,
    country: null,
    city: null,
    postal_code: null,
    state: null,
    line1: null,
    bank_name: null,
    bank_branch: null,
    account_number: '80000001',
    account_name: null,
    pay_money: null,
    till_number: '690745',
    paybill_number: '810592',
    niobi_balance_account_id: null,
    phone_number: '254721548378',
    created_at: '2023-03-06T14:30:33.000000Z',
    updated_at: '2023-03-13T12:05:27.000000Z',
  },
];
