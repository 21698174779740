import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from "@mui/material"
import { SearchInput } from "../../../../../ui/ReuseableComponents/Inputs/Inputs"
import AddANewPayee from "../../../../../ui/ReuseableComponents/Payee/PayeeComponents"
import "./expresspayment.scss"
import Step1 from './Components/Step1/Step1';
import Step2 from "./Components/Step2/Step2";
import Step3 from './Components/Step3/Step3';
import Step4 from './Components/Step4/Step4';
import Step5 from './Components/Step5/Step5';
import AddNewPayee from './Components/Step1/Components/AddNewPayee';
import Model from '../../../../../ui/ReuseableComponents/Model/Model';
import { useDispatch } from 'react-redux';
import { payablesFlowactions } from '../../../../../../_actions';
import { useSelector } from 'react-redux';
import { settingSelector } from '../../../../../../_reducers/setting.reducer';



const ExpressPayment = ({ setGoBackButton, setCurrencyedit, Currencyedit, setCountriyIdEDIT, CountriyIdEDIT, SelectCountry_id, SelectBlence, stepupdatefun, Step, setSelectedUser, selectedUser, setIsAddPayeeComponent, isAddPayeeComponent, SelectFleg, SelectCurrency, setUseLayout, setIsStepsNavHidden }: any) => {
  const [render, setRender] = useState(true)
  const [getPayees, setGetPayees] = useState([])
  // const [selectpayees, setSelectPayees] = useState<any>()
  const [searchPayee, setSearchPayee] = useState("")
  const [loader, setLoader] = useState(false)
  const [loaderPayment, setLoaderPayment] = useState(false)
  const [teamData, setTeamData] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([])
  const [loaderStatus, setLoaderStatus] = useState('')
  const [bankData, setBankData] = useState<any[]>([])
  const [setpprops, setStepprops] = useState(0)
  const [errorsMsg, setErrorMsg] = useState('')
  const [errorsmsgapi, setErrorMsgapi] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [Reference, setReference] = useState()
  const [UpdetPayeeId, setUpdetPayeeId] = useState('')
  const [amount, setAmount] = useState(0)
  const [loaderRender, setLoaderReander] = useState(false)
  const [transaction_detailReload, setTransaction_detailReload] = useState(false)
  const [time, setTime] = useState(0);
  const [enableButton, setEnableButton] = useState(false);
  const [TransactionSuccess, setTransactionSuccess] = useState({})
  const [notifyPayment, setNotifyPayment] = useState(false);
  const [isSchedulePayment, setIsSchedulePayment] = useState(false);
  const [errorsMsgText, setErrorMsgText] = useState({
    counterpart: "Please select the Counterpart.",
    currency: "Please select the Currency.",
    amount: "Please enter the Amount.",
    tags: "Please select the tags.",
    country_id: "Please select the Country Id.",
    mothed: "Please select the Method."
  })
  const [formik, setFormik] = React.useState<any>({
    counterpart: {},
    currency: "KES",
    invoice_record: "",
    vat: "",
    amount: 0,
    description: "",
    tags: [],
    teams: [],
    type: '',
    country_id: "1",
    verified_account_name: "",
    Fees: 0,
    balance: "",
    payment_channel: "",
    correspondent: "",
    method_title: "",
    method_no: "",
    paybill_number: '',
    paybill_account_number: '',
    "bank_name": '',
    "bank_code": '',
    bank_account_number: '',
    date: undefined,
    time: undefined,
    payment_method: ''
  })

  const [users, setUsers] = useState<any>();
  const userData = useSelector((state) => settingSelector(state));

  useEffect(() => {
    if (userData?.GETUSERDETAIL_SUCCESS) {
      setUsers(userData.GETUSERDETAIL_SUCCESS);
      console.log(users, "wwusers")
    }
  }, [SelectCurrency]);

  const dispatch = useDispatch()
  console.log(formik, errorsMsg, Reference, loaderStatus, enableButton, "Reference");

  const getCounterparts = (data: any) => {
    console.log(data, "datadata");

    setGetPayees(data)
    setLoader(false)
  }
  // useEffect(() => {
  //   setStepprops(Step)

  // }, [Step])
  // useEffect(() => {
  //   if (render) {
  //     setLoader(true)

  //   }
  // }, [render])


  useEffect(() => {
    if (time > 0 && enableButton == false) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time, enableButton]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doneTyping();
    }, 1000);
    return () => clearTimeout(timeoutId);
    // }
  }, [SelectCurrency, searchPayee])
  const doneTyping = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
  }
  const getTeam = (data: any) => {
    setTeamData(data)
  }
  const getTagNameApi = () => {
    dispatch(payablesFlowactions.getApipayment("/counterparts/get-tags", getTagName) as any);

  }
  useEffect(() => {
    if (render) {
      dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
      dispatch(payablesFlowactions.getApipayment("/counterparts/get-tags", getTagName) as any);
      dispatch(payablesFlowactions.getApipayment("/bank/get-pesalink-bank-list", getBank) as any);
      dispatch(payablesFlowactions.getApipayment("/team/get-team", getTeam) as any);
      setRender(false)
    }
  }, [render]);
  const getTagName = (data: any) => {
    setCategoriesData(data)
  }
  console.log(categoriesData, "saaaaa")

  const getBank = (data: any) => {
    setBankData(data)
  }
  console.log(bankData, "bankData")
  console.log(selectedUser, formik, "selectedUser");

  useEffect(() => {
    if (!!selectedUser) {
      console.log(selectedUser, "selectedUser");
      formik.counterpart = selectedUser
      setFormik({ ...formik, counterpart: selectedUser })
    }
  }, [selectedUser])
  console.log(formik.counterpart, "selectedUser");

  useEffect(() => {
    if (!!SelectCurrency) {
      formik.currency = SelectCurrency
      setFormik({ ...formik, currency: SelectCurrency })
      // formik.setFieldValue('country_id',method.country_id)

    }

  }, [SelectCurrency])
  useEffect(() => {
    if (!!SelectCountry_id) {
      formik.country_id = SelectCountry_id
      setFormik({ ...formik, country_id: SelectCountry_id })
    }
  }, [SelectCountry_id])
  useEffect(() => {
    if (!!SelectBlence) {
      setFormik({ ...formik, balance: SelectBlence })
    }
  }, [SelectBlence])
  const ExpeshPaymentRes = (data: any, status: any) => {
    if (status == "success") {
      if (formik.currency == 'USD' || formik.payment_channel == "rtgs" || formik.payment_channel == "eft") {
        setLoaderStatus("success_USD")
      } else {
        setReference(data?.data?.reference)
        ExpressPaymentStatus(data?.data?.reference)
      }
    } else {
      setLoaderStatus('failed')
    }
  }
  const ExpressPaymentStatusRes = (data: any) => {


    if (data?.status == "pending") {

      if (!!data?.transaction_id) {
        setTimeout(() => {
          setLoaderStatus("waiting")
        }, 1800000)
        setTimeout(() => {
          ExpressPaymentStatus(data.transaction_id)
        }, 2000)
      }
    } else if (data?.status == "failed") {
      setLoaderStatus("failed")

    } else if (data?.status == "success") {
      setTransactionSuccess(data)
      setLoaderStatus("success")
      setTimeout(() => {
        setTransaction_detailReload(true)
      }, 3000)
    }
  }
  const ExpressPaymentStatus = (id: any) => {
    dispatch(payablesFlowactions.getApipayment(`/wallet/get-transaction-status/${id}`, ExpressPaymentStatusRes) as any);

  }
  const ExpressPayment = (otp: any) => {

    var body = {
      "otp": otp, // Newly added
      // "account_number": 80000003, // Newly added
      "counterpart": formik.counterpart,
      "invoice_record": formik.invoice_record,
      "vat": formik.vat,
      "amount": Number(formik.amount),
      "description": formik.description,
      "tags": formik.tags?.map((item: any) => {
        return item.id;
      }),
      "teams": formik.teams?.map((item: any) => {
        return item.id;
      }),
      "payment_method_type": formik.payment_channel,
      "currency": formik.currency,
      "country_id": formik.country_id,
      // "verified_account_name": ""
    }
    setLoaderStatus("pending")
    stepupdatefun(4)
    dispatch(payablesFlowactions.PostPaymentfull("/v4/express-payment", body, ExpeshPaymentRes, setLoaderPayment, false) as any)

  }

  console.log(formik.amount < 10 && formik.type == "send money", formik.type == "send money", formik.amount < 10, errorsMsg, "formik.amount < 10 && formik.type");

  console.log("selecyted use", selectedUser)
  useEffect(() => {
    if (formik.amount < 0 || formik.amount == 0) {
      setErrorMsg("Please enter an amount greater than 0.")
    } else if (formik.amount < 10 && formik.type == "send money") {
      setErrorMsg("For Send Money, please enter an amount greater than or equal to 10.")
    } else if (formik.currency == "USD" && formik.amount <= 100) {
      setErrorMsg("Please enter an amount greater than or equal to USD 100.")
    } else if (formik.amount > 999999 && !formik.invoice_record) {
      setErrorMsg("The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.Please attach a supporting document for the transaction.")
    } else if (formik.currency == "USD" && formik.invoice_record) {
      setErrorMsg("Please attach a supporting document for the transaction.")
    } else {
      setErrorMsg("")
    }
  }, [formik])
  console.log(formik, "feefee");

  const UpdetPayee = (id: any) => {
    setUpdetPayeeId(id)
  }
  const Otpres = (data: any) => {
    console.log(data.success, "otpresdata")
    if (data.success == true) {
      setEnableButton(false)
      setLoader(false)
      stepupdatefun(3)
      setUseLayout("popuplayout")
      setTime(120);
      setTimeout(() => {
        setEnableButton(true)
      }, 120000)
    }

  }
  const Submitsetp3or4 = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/get-otp`, Otpres) as any);
  }
  return (
    <Box className="express_payment_conatiner">
      {loaderRender &&
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 150,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress color="success" />
        </Box>}
      {Step == 0 ?
        <Step1
        setLoaderReander={setLoaderReander}
          setGoBackButton={setGoBackButton}
          setUpdetPayeeId={setUpdetPayeeId}
          setCountriyIdEDIT={setCountriyIdEDIT}
          CountriyIdEDIT={CountriyIdEDIT}
          Currencyedit={Currencyedit}
          setCurrencyedit={setCurrencyedit}
          UpdetPayeeId={UpdetPayeeId}
          selectedCurrency={SelectCurrency}
          formik={formik}
          loader={loader}
          bankData={bankData}
          searchPayee={searchPayee}
          setSelectedUser={setSelectedUser}
          setSearchPayee={setSearchPayee}
          SelectFleg={SelectFleg}
          getPayees={getPayees}
          stepupdatefun={stepupdatefun}
          setIsAddPayeeComponent={setIsAddPayeeComponent}
          isAddPayeeComponent={isAddPayeeComponent}
        />
        : Step == 1 ?
          <Step2 setGoBackButton={setGoBackButton} getTagNameApi={getTagNameApi} setTeamData={setTeamData} setAmount={setAmount} amount={amount} users={users} setIsStepsNavHidden={setIsStepsNavHidden} setUseLayout={setUseLayout} ExpressPayment={ExpressPayment} Submitsetp3or4={Submitsetp3or4} enableButton={enableButton} setEnableButton={setEnableButton} time={time} setTime={setTime} isSchedulePayment={isSchedulePayment} setIsSchedulePayment={setIsSchedulePayment} UpdetPayee={UpdetPayee} setFormik={setFormik} errorsMsgText={errorsMsgText} submitted={submitted} setSubmitted={setSubmitted} setErrorMsgapi={setErrorMsgapi} errorsmsgapi={errorsmsgapi} errorsMsg={errorsMsg} selectedCurrency={SelectCurrency} selectedFlag={SelectFleg} formik={formik} selectpayees={selectedUser} categoriesData={categoriesData} teamData={teamData} stepupdatefun={stepupdatefun} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} />
          : Step == 2 ?
            <Step3 setGoBackButton={setGoBackButton} users={users} selectedFlag={SelectFleg} loader={loader} Submitsetp3or4={Submitsetp3or4} setUseLayout={setUseLayout} formik={formik} stepupdatefun={stepupdatefun} />
            : Step == 3 ?
              <Step4 setGoBackButton={setGoBackButton} setEnableButton={setEnableButton} setTime={setTime} enableButton={enableButton} time={time} Submitsetp3or4={Submitsetp3or4} setUseLayout={setUseLayout} ExpressPayment={ExpressPayment} formik={formik} stepupdatefun={stepupdatefun} />
              : Step == 4 ?
                <Step5 setGoBackButton={setGoBackButton} loader={loader} setLoader={setLoader} setTransaction_detailReload={setTransaction_detailReload} TransactionSuccess={TransactionSuccess} paymentDeatils={formik} type={formik.type} notifyPayment={notifyPayment} setNotifyPayment={setNotifyPayment} loaderStatus={loaderStatus} transactionDetailsReload={transaction_detailReload} setLoaderStatus={setLoaderStatus} formik={formik} stepupdatefun={stepupdatefun} /> : ""
      }
    </Box>
  )
}

export default ExpressPayment;