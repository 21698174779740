import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import { DefaultBtn } from "../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import { DefaultGoBackBtn } from "../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import "./step1.scss"
import { MuiCustomDatePicker } from "../../../../../../../../../ui/ReuseableComponents/Pickers/Pickers";
import { MuiCustomTimePicker } from "../../../../../../../../../ui/ReuseableComponents/Pickers/Pickers";
import { useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import dayjs from "dayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../../../../../../../../../ui/ReuseableComponents/Transaction_Summary/Transaction_Summary.module.scss';
const Step1 = ({ setScheduleStep,
  setFormik,
  setIsSchedulePayment,
  formik, selectedPayee,
  setIsStepsNavHidden,
}: any) => {



  useEffect(() => {
    toast.warn(`Please verify that the date and time on your device are accurate before selecting date and time.`);
  }, []);

  const handleDateChange = (date: any) => {
    const newDate = dayjs(date);
    setFormik({ ...formik, date: newDate });
  };



  return (
    <Box className="schedule_step1_container">
      <DefaultGoBackBtn style={{ marginTop: "-60px" }} onClick={() => {
        setIsSchedulePayment(false)
        setIsStepsNavHidden(false)
      }} />
      <Typography className="schedule_heading">Schedule for later</Typography>
      <Box className="d-flex flex-column gap-4">
        <Box>
          <MuiCustomDatePicker
            label="Date"
            value={formik.date}
            onChange={handleDateChange}
          />
        </Box>
        <Box sx={{ paddingBottom: "35px" }}>
          <MuiCustomTimePicker
            label="Time"
            value={formik.time}
            selectedDate={formik.date}
            onChange={(time: any) => {
              setFormik({ ...formik, time: time })
              console.log(formik.time, "wajidformiktime")
            }}
          />
        </Box>
      </Box>
      {formik.selectedBills.map((item: any, index: number) => {

        return (
          <Accordion sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ "& .MuiAccordionSummary-content": { margin: "3px 0px" } }}
              className={styles.AccordionSummary_calll_list}
            >
              <div className={styles.div_main_contean_llooo}
              >

                <p className={styles.text_heding_clorrrrr}>
                  {item.counterpart_info.counterpart_name} Details</p>

              </div>
            </AccordionSummary>
            <AccordionDetails className={styles.maianahahgs_accodinggg} sx={{ "& .MuiAccordionDetails-root": { padding: "0px" } }} >
              <Box className="d-flex justify-content-between pt-1 pb-1" >
                <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Payee name</Typography>
                <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{item.counterpart_info.counterpart_name}</Typography>
              </Box>
              <Box className="d-flex justify-content-between pt-1 pb-1" >
                <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Amount</Typography>
                <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{item.currency}{item.amount}</Typography>
              </Box>
              {/* <Box className="d-flex justify-content-between pt-1 pb-1" >
                <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Vat</Typography>
                <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{!!item.vat?`${item.vat}%`:'-'}</Typography>
              </Box> */}
              <Box className="d-flex justify-content-between pt-1 pb-1" >
                <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Fee</Typography>
                <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{!!item.fees?`${item.currency}${item.fees}`:'-'}</Typography>
              </Box>
              <Box className="d-flex justify-content-between pt-1 pb-1" >
                <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Category</Typography>
                {item?.bulk_payment?.categories?.length === 0 ? "-" : item?.bulk_payment.categories.map((category: any, index: number) => (
              <Typography key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                {category.name}
              </Typography>

            ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      })}
      {/* <Box className="d-flex flex-column schedule_payment_detail_box mt-5">
        <Typography className="mb-3" style={{ fontSize: "16px", fontWeight: "500 ", fontFamily: 'Poppins' }}>Payment Details</Typography>
        <Box className="d-flex justify-content-between pt-2 pb-2" style={{ borderBottom: "1px solid #e0e0e0" }}>
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Payee name</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{selectedPayee?.name ? selectedPayee.name : "-"}</Typography>
        </Box>
        <Box className="d-flex justify-content-between pt-2 pb-2" style={{ borderBottom: "1px solid #e0e0e0" }}>
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Amountt</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{formik.currency} {formik?.amount ? formik.amount : "-"}</Typography>
        </Box>
        <Box className="d-flex justify-content-between pt-2 pb-2" style={{ borderBottom: "1px solid #e0e0e0" }}>
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Vat</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{formik?.vat ? formik.vat : "-"}</Typography>
        </Box>
        <Box className="d-flex justify-content-between pt-2 pb-2" style={{ borderBottom: "1px solid #e0e0e0" }}>
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Fee</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>{formik?.Fees / 100} {formik.currency}</Typography>
        </Box>
        <Box className="d-flex justify-content-between pt-2 pb-2" style={{ borderBottom: "1px solid #e0e0e0" }}>
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Category</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>
            {formik?.selectedBills?.length === 0 ? "-" : formik?.tags.map((category: any, index: number) => (
              <Typography key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                {category.name}
              </Typography>

            ))}
          </Typography>
        </Box>
        <Box className="d-flex justify-content-between pt-2 pb-2">
          <Typography className="color-secondary" style={{ fontFamily: "poppins", fontSize: "14px" }}>Team</Typography>
          <Typography style={{ fontFamily: "poppins", fontSize: "14px" }}>
            {formik.teams.length === 0 ? "-" : formik.teams.map((team: any, index: number) => (
            <Typography key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
              {team.name}
            </Typography>
          ))}
          </Typography>
        </Box>
      </Box> */}
      <DefaultBtn
        disabled={!!formik?.date && !!formik?.time ? false : true}
        onClick={() => setScheduleStep(1)}
        text="Schedule Payment" style={{ width: "100%", marginTop: "30px" }} />

    </Box>


  )
}

export default Step1;