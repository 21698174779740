
import styles from './Transaction_Summary.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { CssBaseline, CircularProgress } from '@mui/material';
import PageHeader from '../../../app/Layout/PageHeader';
import { DefaultBtn } from '../Buttons/Buttons';
import { payablesActions } from '../../../../_actions/payables.actions';
import { selectAllCounterpartsListData } from '../../../../_reducers/counterparts.reducer';
import { userActions, dashboardActions } from '../../../../_actions';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { isUserAuthenticated } from '../../../../_helpers';
import { selectAllPayablesListData } from '../../../../_reducers/payables.reducer';
import { getKESFormateCurrency } from '../../../../_helpers/common';
import { selectAllData } from '../../../../_reducers/dashboard.reducer';

interface Props {
    onSave: any;
    onCancel: any;
    display?: string;
    margin?: any;
    loader?: any;
    text?: any;
    data?: any;
}
const Transaction_Summary = (props: Props) => {
    const [payeeData, setPayeeData] = React.useState<any>([])
    const [Till_Number, setTill_number] = React.useState<any>([])
    const [PayBill, setPayBill] = React.useState<any>([])
    const [Send_Money, setSend_Money] = React.useState<any>([])
    const [Bank, setBank] = React.useState<any>([])
    const [bankListRender, setBankListRender] = React.useState<any>(true);
    const [bankList, setBankList] = React.useState<any>([]);
    const [render, setRender] = React.useState(true);
    const [Till_Number_Subtotal, setTill_number_Subtotal] = React.useState<any>([])
    const [PayBill_Subtotal, setPayBill_Subtotal] = React.useState<any>([])
    const [Send_Money_Subtotal, setSend_Money_Subtotal] = React.useState<any>([])
    const [Bank_Subtotal, setBank_Subtotal] = React.useState<any>([
    ])
    const [balance, setBalance] = useState<any>();
    const [balanceRender, setBalanceRender] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [requestRender, setrequestRender] = React.useState(true);
    const [fototer_detels, setFototer_detels] = React.useState<any>()
    const [Balanceloader, setBalanceloader] = React.useState(false)
    const [paybleRender, setPaybleRender] = React.useState(true);

    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const payablesListData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    const data = useSelector((state) => selectAllData(state));
    console.log(paybleRender, props?.data?.data, "Till_Number_Subtotal");

    const dispatch = useDispatch();
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
        }
    }, [render, isUserAuthenticated])
    React.useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
            dispatch(dashboardActions.getBalance("/wallet/get-balance", setBalanceRender, setBalanceloader) as any);
        }
    }, [dataList, bankListRender]);
    React.useEffect(() => {
        if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
            setTimeout(() => {
                setPaybleRender(true)
            }, 3000)
        }
    }, [data, balanceRender]);
    console.log(paybleRender && props?.data !== undefined, "paybleRender && props?.data !== undefined");

    React.useEffect(() => {
        if (paybleRender && props?.data !== undefined) {
            var newarray: any = [];
            var newarray_two: any = []
            var newarray_three: any = []
            var newarray_fore: any = []
            var Till_Number_array: any = []
            var paybill_arrr: any = []
            var send_meny_array: any = []
            var bank_many: any = []
            for (let index = 0; index < props?.data?.data?.length; index++) {
                const element_liststs = props?.data?.data[index];
                console.log(element_liststs, "elementelement");

                for (let index = 0; index < element_liststs?.data?.length; index++) {
                    const element = element_liststs?.data[index];
                    const find_name = bankList.find((item: any) => {
                        return item.id == element.bulk_payment.bank_name
                    })
                    console.log(element, "elementelement");

                    var object: any = {
                        account_number: element.bulk_payment.account_number,
                        amount: element.bulk_payment.amount / 100,
                        fee: element.fee,
                        counterpart_name: element.counterpart_name
                    }
                    var object_two: any = {
                        account_number: element.bulk_payment.account_number,
                        amount: element.bulk_payment.amount / 100,
                        paybill_number: element.bulk_payment.paybill_number,
                        fee: element.fee,
                        counterpart_name: element.counterpart_name
                    }
                    var object_three: any = {
                        account_number: element.bulk_payment.account_number,
                        amount: element.bulk_payment.amount / 100,
                        bank_name: element.bulk_payment.bank_name,
                        account_name: element.bulk_payment.account_name,
                        fee: element.fee,
                        counterpart_name: element.counterpart_name
                    }
                    if (element.bulk_payment.payment_method == 'M-Pesa Till Number') {
                        newarray.push(object)
                    }
                    if (element.bulk_payment.payment_method == 'M-Pesa PayBill') {
                        newarray_two.push(object_two)
                    }
                    if (element.bulk_payment.payment_method == 'M-Pesa Send Money') {
                        newarray_three.push(object)
                    }
                    if (element.bulk_payment.payment_method == 'Bank Transfers') {
                        newarray_fore.push(object_three)
                    }
                }

                var Till_Number_Subtotal: any = {
                    payment_method: element_liststs.payment_method,
                    subtotal: element_liststs.subtotal
                }
                if (element_liststs.payment_method == 'M-Pesa Till Number') {
                    Till_Number_array.push(Till_Number_Subtotal)
                }
                if (element_liststs.payment_method == 'M-Pesa PayBill') {

                    paybill_arrr.push(Till_Number_Subtotal)
                }
                if (element_liststs.payment_method == 'M-Pesa Send Money') {

                    send_meny_array.push(Till_Number_Subtotal)
                }
                if (element_liststs.payment_method == 'Bank Transfers') {

                    bank_many.push(Till_Number_Subtotal)
                }

            }
            var object_ffotrt = {
                gross_total: props?.data?.payout_details?.gross_total / 100,
                total_transactions: props?.data?.payout_details?.total_transactions,
                total_fees: props?.data?.payout_details?.total_fees,
                balance: !!balance && balance[0]?.available_balance ? (balance[0]?.available_balance / 100) : 0,
                total_net_payout: (props?.data?.payout_details?.gross_total / 100) + props?.data?.payout_details?.total_fees,
                remaining_balance: !!balance && balance[0]?.available_balance ? (balance[0]?.available_balance / 100) : 0 - (props?.data?.payout_details?.gross_total + props?.data?.payout_details?.total_fees)

            }


            setTill_number_Subtotal(Till_Number_array)
            setPayBill_Subtotal(paybill_arrr)
            setSend_Money_Subtotal(send_meny_array)
            setBank_Subtotal(bank_many)
            setTill_number(newarray)
            setPayBill(newarray_two)
            setSend_Money(newarray_three)
            setBank(newarray_fore)
            setFototer_detels(object_ffotrt)
            setPaybleRender(false)
        }
    }, [props, paybleRender]);

    // React.useEffect(() => {
    //     if (requestRender && counterpartsListData.UPDATE_BULK_PAYMENT_PAYABLES_REQUEST == true) {
    //         setLoading(counterpartsListData.UPDATE_BULK_PAYMENT_PAYABLES_REQUEST);
    //         setresponseRender(true);
    //         setrequestRender(false);
    //     }
    // }, [counterpartsListData, requestRender]);

    // React.useEffect(() => {
    //     if (responseRender && counterpartsListData.UPDATE_BULK_PAYMENT_PAYABLES_REQUEST == false) {
    //         setLoading(false);
    //         setrequestRender(true);
    //         setresponseRender(false);
    //     }
    // }, [counterpartsListData, responseRender]);
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: { primary: { main: '#000' } },
    });
    const onSave = () => {
        // dispatch(userActions.getOtp('') as any)
        // dispatch(payablesActions.getOtpverifay() as any);
        props.onSave(fototer_detels);
    }
    const onCancel = () => {
        props.onCancel();
    }
    console.log(props, "sadafaffff");

    return ( 
        <>
            <div style={{ display: props.display || 'block' }} className="bill-container">
                <div className="bill-container__payables">
                    <PageHeader
                        title="Confirm Details"
                    />

                </div>
            </div >
            <div style={{ margin: props.margin || '40px 120px' }} className={`${styles.contenar_datafooterline} ${styles.custom_trans_summary_container}`}>
                <p className={styles.text_heding_data_centerr}>
                    Confirm Details
                </p>

                <div className={styles.main_contenar_lineene_border}>
                    <ThemeProvider theme={theme}>
                        {loading && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                            <CircularProgress color="success" />
                        </Box>}
                        {Send_Money_Subtotal.length > 0 ?
                            <Accordion sx={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ "& .MuiAccordionSummary-content": { margin: "3px 0px" } }}
                                    className={styles.AccordionSummary_calll_list}
                                >
                                    <div className={styles.div_main_contean_llooo}
                                    >

                                        <p className={styles.text_heding_clorrrrr}>
                                            M-Pesa Send Money</p>
                                        <p className={styles.hedingg_center_lineejee}>
                                            Subtotal
                                        </p>
                                        <p style={{ width: "48%", textAlign: "end" }} className={styles.hedingg_center_lineejee}>
                                            KES {Send_Money_Subtotal.length > 0 ? Send_Money_Subtotal[0]?.subtotal / 100 : ""}
                                        </p>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.maianahahgs_accodinggg} sx={{ "& .MuiAccordionDetails-root": { padding: "0px" } }} >

                                    {Send_Money && Send_Money.length > 0
                                        ? Send_Money.map((user: any, index: any) => {
                                            return (
                                                <div className={styles.main_div_footer_accrdion}>
                                                    <p className={styles.text_benifisal_datatta}>{user.counterpart_name}</p>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            {user.account_number}
                                                        </p>
                                                        <p className={styles.bload_clor_text_filddd}>
                                                            KES {user?.amount}
                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Transaction fee
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            KES {user.fee}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                    }

                                </AccordionDetails>
                            </Accordion> : ""}
                        {PayBill_Subtotal.length > 0 ?
                            <Accordion sx={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ "& .MuiAccordionSummary-content": { margin: "3px 0px" } }}
                                    className={styles.AccordionSummary_calll_list}
                                >
                                    <div className={styles.div_main_contean_llooo}
                                    >

                                        <p className={styles.text_heding_clorrrrr}>
                                            M-Pesa Paybill</p>
                                        <p className={styles.hedingg_center_lineejee}>
                                            Subtotal
                                        </p>
                                        <p style={{ width: "48%", textAlign: "end" }} className={styles.hedingg_center_lineejee}>
                                            KES {PayBill_Subtotal.length > 0 ? PayBill_Subtotal[0]?.subtotal / 100 : ""}
                                        </p>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.maianahahgs_accodinggg} sx={{ "& .MuiAccordionDetails-root": { padding: "0px" } }} >
                                    {PayBill && PayBill.length > 0
                                        ? PayBill.map((user: any, index: any) => {
                                            return (
                                                <div className={styles.main_div_footer_accrdion}>
                                                    <p className={styles.text_benifisal_datatta}>{user.counterpart_name}</p>
                                                    <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Paybill number
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            {user.paybill_number}

                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                Account number
                                                            </p>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                {user.account_number}
                                                            </p>
                                                        </div>
                                                        <p id={styles.id_amount} className={styles.bload_clor_text_filddd}>
                                                            KES {user?.amount}
                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Transaction fee
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            KES {user.fee}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                    }

                                </AccordionDetails>
                            </Accordion> : ""}
                        {Till_Number_Subtotal.length > 0 ?
                            <Accordion sx={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ "& .MuiAccordionSummary-content": { margin: "3px 0px" } }}
                                    className={styles.AccordionSummary_calll_list}
                                >
                                    <div className={styles.div_main_contean_llooo}
                                    >

                                        <p className={styles.text_heding_clorrrrr}>
                                            M-Pesa Till</p>
                                        <p className={styles.hedingg_center_lineejee}>
                                            Subtotal
                                        </p>
                                        <p style={{ width: "48%", textAlign: "end" }} className={styles.hedingg_center_lineejee}>
                                            KES {Till_Number_Subtotal.length > 0 ? Till_Number_Subtotal[0]?.subtotal / 100 : ""}
                                        </p>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.maianahahgs_accodinggg} sx={{ "& .MuiAccordionDetails-root": { padding: "0px" } }} >
                                    {Till_Number && Till_Number.length > 0
                                        ? Till_Number.map((user: any, index: any) => {
                                            return (
                                                <div className={styles.main_div_footer_accrdion}>
                                                    <p className={styles.text_benifisal_datatta}>{user.counterpart_name}</p>

                                                    <div className={styles.main_contean_lineeeee}>
                                                        <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                Till number
                                                            </p>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                {user.account_number}
                                                            </p>
                                                        </div>
                                                        <p className={styles.bload_clor_text_filddd}>
                                                            KES {user?.amount}
                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Transaction fee
                                                        </p>
                                                        <p id={styles.id_amount} className={styles.p_number_listtt_contean}>
                                                            KES {user.fee}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                    }

                                </AccordionDetails>
                            </Accordion> : ""}
                        {Bank_Subtotal.length > 0 ?
                            <Accordion sx={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ "& .MuiAccordionSummary-content": { margin: "3px 0px" } }}
                                    className={styles.AccordionSummary_calll_list}
                                >
                                    <div className={styles.div_main_contean_llooo}
                                    >

                                        <p className={styles.text_heding_clorrrrr}>
                                            Bank Transfers</p>
                                        <p className={styles.hedingg_center_lineejee}>
                                            Subtotal
                                        </p>
                                        <p style={{ width: "48%", textAlign: "end" }} className={styles.hedingg_center_lineejee}>
                                            KES {Bank_Subtotal.length > 0 ? Bank_Subtotal[0]?.subtotal / 100 : ""}
                                        </p>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.maianahahgs_accodinggg} sx={{ "& .MuiAccordionDetails-root": { padding: "0px" } }} >
                                    {Bank && Bank.length > 0
                                        ? Bank.map((user: any, index: any) => {
                                            return (
                                                <div className={styles.main_div_footer_accrdion}>
                                                    <p className={styles.text_benifisal_datatta}>{user.counterpart_name}</p>
                                                    <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Bank name
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            {user.bank_name}
                                                        </p>
                                                    </div>
                                                    <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Account name
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>

                                                            {user.account_name}
                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <div className={styles.contean_paybill} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "40%" }}>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                Account number
                                                            </p>
                                                            <p className={styles.p_number_listtt_contean}>
                                                                {user.account_number}
                                                            </p>
                                                        </div>
                                                        <p id={styles.id_amount} className={styles.bload_clor_text_filddd}>
                                                            KES {user?.amount}
                                                        </p>
                                                    </div>
                                                    <div className={styles.main_contean_lineeeee}>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            Transaction fee
                                                        </p>
                                                        <p className={styles.p_number_listtt_contean}>
                                                            KES {user.fee}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                    }
                                </AccordionDetails>
                            </Accordion> : ""}
                    </ThemeProvider>
                </div>

                <div className={styles.main_contenar_lineene_border_twoooo}>
                    <div className={styles.mainn_contenar_datataaa}>

                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>
                                Total Transaction
                            </p>
                            <p className={`${styles.amountt_text_shhshg} ${styles.para_wrapper}`}>
                                {!!fototer_detels ? fototer_detels?.total_transactions : 0}
                            </p>
                        </div>
                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>
                                Total Net Payout
                            </p>
                            <p className={`${styles.amountt_text_shhshg} ${styles.para_wrapper}`}>
                                {getKESFormateCurrency(!!fototer_detels ? fototer_detels?.total_net_payout : 0)}
                            </p>
                        </div>
                    </div>
                    <div className={styles.mainn_contenar_datataaa}>

                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>
                                Total Amount
                            </p>
                            <p className={`${styles.amountt_text_shhshg} ${styles.para_wrapper}`}>
                                {getKESFormateCurrency(!!fototer_detels ? fototer_detels?.gross_total : 0)}
                            </p>
                        </div>
                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>
                                Balance
                            </p>
                            <p className={`${styles.amountt_text_shhshg} ${styles.para_wrapper}`}>
                                {getKESFormateCurrency(!!fototer_detels ? fototer_detels?.balance : 0)}
                            </p>
                        </div>
                    </div>
                    <div className={styles.mainn_contenar_datataaa}>

                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>
                                Total Fees
                            </p>
                            <p className={styles.amountt_text_shhshg}>
                                {getKESFormateCurrency(!!fototer_detels ? fototer_detels?.total_fees : 0)}

                            </p>
                        </div>
                        <div className={styles.main_div_ptageee}>
                            <p className={`${styles.main_contean_list_ptagee} ${styles.para_wrapper}`}>

                                Remaining Balance
                            </p>
                            <p className={`${styles.amountt_text_shhshg} ${styles.para_wrapper}`}>
                                {getKESFormateCurrency(!!fototer_detels ? fototer_detels?.balance - fototer_detels?.total_net_payout : 0)}
                            </p>
                        </div>
                    </div>
                </div>
                {!!fototer_detels && fototer_detels.balance < fototer_detels.total_net_payout ?
                    <div className='mpesa-container__errorMsg' style={{ paddingTop: "13px" }}>
                        Please top up your account to cover the payment.</div> : ""}
                <div className={`${styles.add_ajajjahahag} ${styles.summary_div_wrapper}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0px', marginTop: "15px" }}>
                    <div className={`${styles.add_delete_data_listtss} ${styles.summary_btn_wrapper_div}`}>


                        <Button color="#025041" className={`${styles.white_btn} ${styles.summary_flex_btn}`}
                            style={{ marginLeft: "10px", padding: "12px 30px" }}
                            onClick={() => { onCancel() }}>
                            Go Back
                        </Button>
                    </div>
                    <div className={`${styles.div_laiiaajjajjaa} ${styles.summary_btn_wrapper_div}`} >
                        {/* <DefaultBtn
                            loader={props.loader}
                            onClick={() => (onSave())}
                            text={props.text} style={{ width: "100%" }} /> */}

                        <Button

                            type="submit"
                            color="#025041"
                            className={`${styles.save_btn} ${styles.summary_flex_btn}`}
                            onClick={() => (onSave())}
                        >
                            <span style={{ paddingRight: "9px" }}>
                                {props.loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                            </span>
                            Proceed to Pay                                                  </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Transaction_Summary;